/* General css  */
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
::-webkit-scrollbar{
  width: 5px;
  border-radius: 80px;
}
::-webkit-scrollbar-track {
  background: #e5fed15a;
  border-radius: 10px;
  width: 2px;
}
::-webkit-scrollbar-thumb {
  background: var(--base-green);
  border-radius: 10px;
}

body {
  font-family: ‘Outfit’, sans-serif;
  background-color: var(--base-lightgray);
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: var(--base-default-text);
  -webkit-font-smoothing: antialiased;
}
a:hover {
  color: var(--base-secondry);
}
/* Colors */
:root {
  /* --base-primary: #002358; */
  --base-primary: #2fb84c;
  --base-secondry: #171717;
  --tab-text: #121212;
  --base-lightgray: #fafafa;
  --base-darkgray: #8b958e;
  --bg-darkgray: #ebebeb;
  --base-whitesure: #979797;
  --base-offwhite: #6e7280;
  --base-offgray: #ececec;
  --base-offtextgray: #9b9b9b;
  --base-text-lightgray: #aaaaaa;
  --base-purewhite: #ffffff;
  --base-placeholder: #cbcbcb;
  --base-green: #22b84c;
  --base-border: #dddddd;
  --base-level1: #db9102;
  --base-default-text: #545456;
  --base-inputautofill: #212121;
  --base-tablebg: #e1e5ea;
  --darkmode-lightgray: #383838;
  --darkmode-darkblack: #1c1b1b;
  --darkmode-lightblack: #272728;
  --darkmode-offgray: #3a3a3a;
  --darkmode-offwhite: #afafaf;
  --darkmode-gray2xl: #343434;
}
.profile-feature-image {
  -webkit-mask-image: linear-gradient(#000000 94%, #ffffff00);
  mask-image: linear-gradient(#000000 94%, #ffffff00);
}
.notification-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.loginbtn {
  margin-left: 0;
  color: #72c39d;
}
.notification-dropdown .notification-content .notification-text {
  flex: 0 0 90%;
}
.admin-notification-text{
  display: block;
  flex: 0 0 90%;
}
.Dropdown-listing .arrowbar .notification-content .notification-text p{
  line-height: 1.5rem !important;
}
.notification-content .notification-text p {
  line-height: 20px !important;
}
[darkmode] .hoverdropdown .dropdown-menu .dropdown-item p {
  color: #ffffff;
}
.notification-text p {
  margin: 0px 5px 0px 0px !important;
  font-weight: 400 !important;
  font-size: 1.125rem !important;
  line-height: 1.875rem !important;
  color: var(--base-text-lightgray);
}

.report_reasons {
  cursor: pointer;
}

/* Loader */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  background: #00000091;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: 2s ease-in-out;
}

.loader .spinner-border {
  color: var(--base-lightgray);
}

/* Text Gradient */
.text-gradient {
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn {
  border: none !important;
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
}

.btn-primary {
  color: var(--base-secondry) !important;
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  padding: 12px 50px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 18.5965px;
  line-height: 1.375rem;
  transition: 0.3s ease-in-out;
}

.btn-primary:hover {
  background-color: var(--darkmode-darkblack) !important;
  transition: 0.3s ease-in-out;
  color: var(--base-purewhite) !important;
  border-color: #565656 !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--base-primary);
}

p {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: var(--base-default-text);
}

ul li,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  cursor: pointer;
  color: var(--base-primary);
  text-decoration: none;
}

/* a {
  cursor: pointer;
  color: var(--base-primary);
  text-decoration: none;
  background: linear-gradient(180deg, #21A0DA -28.9%, #9DD382 100%), #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */
img {
  max-width: 100%;
}

.form-check-input:checked {
  background-color: var(--base-primary);
  border-color: var(--base-primary);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid var(--base-inputautofill);
  -webkit-text-fill-color: var(--base-purewhite);
  -webkit-box-shadow: 0 0 0px 1000px var(--base-inputautofill) inset;
  transition: background-color 5000s ease-in-out 0s;
  color: var(--base-purewhite) !important;
}

.btn {
  padding: 12px 50px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;
  transition: 0.3s ease-in-out;
}

input.form-control {
  background: var(--base-purewhite);
  border: 1.24px solid var(--base-border);
  border-radius: 4.96px;
  height: auto;
  padding: 20px 60px 20px 20px;
}

.form-control:hover,
.form-control:focus,
.form-control:active {
  outline: none;
  box-shadow: none;
  border: 1px solid var(--base-primary);
}

.Userdetail-editorbar p.word-note {
  font-size: 0.875rem;
}

.level1 {
  color: var(--base-level1);
}

.level1-circle {
  background: var(--base-level1) !important;
}

.outline-primary:hover,
.btn-outline-primary:hover {
  background: var(--base-primary) !important;
  color: var(--base-purewhite) !important;
}

/* Colors */
.text-primary {
  color: #2fb84c !important;
}

.text-primary-highlight {
  cursor: pointer;
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-secondry {
  color: var(--base-secondry);
}

.text-black {
  color: #000000;
}

.text-white {
  color: var(--base-purewhite);
}

.text-gray {
  color: var(--base-lightgray);
}

.text-lightgray {
  color: var(--base-text-lightgray);
}

.text-darkgray {
  color: var(--bg-darkgray);
}

.text-musterd {
  color: var(--base-level1);
}

.text-green {
  color: var(--base-green);
}

.text-offwhite {
  color: var(--base-offwhite);
}

.text-silver {
  color: #656875;
}

.text-whitesure {
  color: var(--base-whitesure);
}

.text-darkwhite {
  color: #939d9e;
}

/* Background Colors */

.bg-primary {
  background-color: var(--base-primary) !important;
}

.bg-secondry {
  background-color: var(--base-secondry);
}

.bg-black {
  background-color: #000000;
}

.bg-white {
  background-color: var(--base-purewhite);
}

.bg-gray {
  background-color: var(--base-lightgray);
}

.bg-lightgray {
  background-color: var(--base-text-lightgray);
}

.bg-darkgray {
  background-color: var(--bg-darkgray);
}

.bg-musterd {
  background-color: var(--base-level1);
}

.bg-green {
  background-color: var(--base-green);
}

/* BS Container */
.container {
  max-width: 1550px;
  padding: 0 30px;
}

/* Header css  */
.header-nav {
  box-shadow: 1px 1px 10px rgb(0,0,0,0.1);
  padding: 10px 0;
  background-color: #fdfdfd;
  position: sticky;
  top: 0;
  z-index: 200;
}

.header-nav .navbar-nav {
  display: flex;
  column-gap: 30px;
  align-items: center;
  justify-content: flex-end;
}

.header-nav .navbar-nav a.nav-link {
  padding: 25px 0;
  display: inline-block;
  margin-left: 60px;
}

.header-nav .navbar-brand {
  padding: 0 !important;
  margin: 0;
}

ul.trending-hashtag-list {
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(204, 204, 204, 0.15);
  border-radius: 12px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(50% - 10px);
  margin-bottom: 25px;
  background: #ffffff;
}
.sidebar-menu.col-lg-3 {
  width: 25%;
  padding-left: 0;
}
.mainwrapper.col-lg-9 {
  width: 75%;
  padding: 0;
}
[darkmode] .terms-content h4,
[darkmode] .terms-content h5 {
  color: #ffffff;
}
[darkmode] .digital-feeds .Description-bar {
  color: var(--darkmode-offwhite);
}
[darkmode] ul.trending-hashtag-list {
  background: #383838;
}
.trending-list-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
ul.trending-hashtag-list li {
  margin-right: 20px;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: #6e7180;
}
ul {
}

ul.trending-hashtag-list {
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(50% - 10px);
  margin-bottom: 25px;
}

.trending-list-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.user-detail-bar .detailleft .awards-bar{display:none !important;}
ul.trending-hashtag-list li {
  margin-right: 20px;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.875rem;
  /* identical to box height, or 136% */
  color: #6e7180;
}
button.ql-user-list {
  display: none !important;
}
.ql-emojiblot {
  line-height: 1;
}
.reply-post{
  z-index: 1;
}
.quill {
  position: relative;
}
#emoji-palette #tab-panel span.bem {
  margin: 3px !important;
}
.Whatsmind-bar .ql-editor p , .ql-editor ol li, .ql-editor ul li, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {display: flex;align-items: center;}
ul.trending-hashtag-list li.hashtaglist {
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: #00c350;
}
ul.trending-hashtag-list li.hashtagcounts {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: right;
  color: #aaaaaa;
}
.notification-title h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.notification-title h4 ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.notification-title h4 ul li.mute {
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  color: #ffffff;
}
#emoji-palette #tab-toolbar ul {
  display: flex;
}
.restrict-logins {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.restrict-logins li {
  margin-right: 20px;
}

.restrict-logins li p {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1;
}

.restrict-logins li .form-switch {
  padding: 0;
}

.restrict-logins li .form-switch input {
  width: 40px;
  margin: 8px 0 0 0;
  cursor: pointer;
}
.notification-title h4 ul li.mute::after {
  content: "";
  width: 3px;
  height: 43px;
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  position: absolute;
  top: -6px;
  left: 13px;
  transform: rotate(30deg);
}
.notification-title h4 ul li {
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid green;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-size: 0.875rem;
  cursor: pointer;
}
.beforeproceed-sestion .beforeproceed {
  margin-bottom: 10px;
}
.beforeproceed-sestion .modal-footer {
  justify-content: center;
}
ul.trending-hashtag-list li.hashtaglist {
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.875rem;
  color: #00c350;
}
.trending-hashtag-list li.hashtagcounts {
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: right;
  color: #aaaaaa;
}
.user-dropdown {
  position: relative;
}

/* .helpicon {
  margin-left: 30px;
} */

.user-dropdown .Dropdown-listing {
  position: absolute;
  top: 60px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 0px;
  right: 0;
  width: 197px;
  border: 2px solid var(--base-border);
  transform: translate(0px, 20px);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease;
  z-index: 999;
}

.user-dropdown .Dropdown-listing h4 {
  font-style: normal;
  font-weight: 700;
  font-size: .75rem;
  line-height: 1.125rem;
  margin-bottom: 10px;
}

.user-dropdown .Dropdown-listing li,
.user-dropdown .Dropdown-listing li a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.75rem;
  cursor: pointer;
  color: var(--base-secondry);
  text-decoration: none;
  width: 100%;
}

.user-dropdown .Dropdown-listing li a {
  margin-bottom: 0 !important;
}

.user-dropdown .Dropdown-listing li svg {
  margin-right: 15px;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
}

.user-dropdown:hover .Dropdown-listing {
  visibility: visible;
  opacity: 1;
  transform: translate(0px, 0px);
}

.user-dropdown .nav-link {
  margin-left: 0 !important;
}

.body-wrapper {
  padding: 30px 0;
}
.sidebar-profile .feature-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}
.sidebar-profile {
  border-radius: 20px;
  background: var(--base-purewhite);
  box-shadow: 0px 4px 24px rgb(204 204 204 / 15%);
  position: sticky;
  top: 175px;
}

.sidebar-profile .feature-image {
  position: relative;
}

.sidebar-profile .feature-image img {
  width: 100%;
}

.sidebar-profile .feature-image .edit-bar {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  width: 31px;
  height: 31px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  cursor: pointer;
  border: 1px solid var(--base-purewhite);
  background: var(--base-purewhite);
}

.sidebar-profile .feature-image .edit-bar .uploadimg-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.User-detail {
  padding: 10px 20px;
}

.User-detail .usermain-img {
  margin-top: -75px;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  column-gap: 14px;
}

.User-detail .usermain-img img {
  width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    /* border: 5px solid var(--base-green); */
}

.user-detail-listing {
  display: flex;
  align-items: center;
justify-content: space-between;
 margin-top: 30px;
}

.user-detail-listing li {
  text-align: center;
}

.user-detail-listing li p {
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 1.125rem;
  text-align: center;
  margin-bottom: 17px;
}

.user-detail-listing li h6 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
}

.User-detail .username {
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.188rem;
  margin: 15px 0 5px 0;
}

.search-input-form .user-searchform {
  position: relative;
}
.share-popup .modal-dialog {
  max-width: 600px !important;
}
.search-input-form ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.search-input-form ul li img {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  margin-right: 5px;
}
.search-input-form .user-searchform input {
  padding: 15px 45px 15px 25px;
  width: 100%;
  font-weight: 400;
  font-size: 15.8162px;
  line-height: 1.188rem;
  border-radius: 8px;
  border: 1px solid #d1d1d1;
  background: #fafafa;
  box-shadow: 0px 0px 5px #8080803d;
}

.search-input-form .user-searchform input::placeholder {
  color: var(--base-placeholder);
}

ul.socialmediaicon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  list-style: none;
}

ul.socialmediaicon li {
  margin: 0 10px;
}
ul.socialmediaicon li a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  text-decoration: none;
  border-radius: 50%;
  font-size: 1.25rem;
  color: #000000;
  transition: 0.5s ease;
  cursor: pointer;
}
.terms-content h4 {
  color: #212121;
  font-weight: bold;
  font-size: 1.375rem;
  margin-bottom: 10px;
  margin-top: 20px;
}
.terms-content p {
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 10px;
}
.terms-content h4:first-child {
  margin-top: 0;
}
.Auth-bar .Authbar-innerbox form .authinputbar button {
  padding: 0;
  background: transparent !important;
  margin-left: 10px;
  font-size: 1rem;
  color: #000000 !important;
  line-height: 1rem;
}
/* small.text-danger {
  line-height: 20px;
  font-size: 0.875rem;
  display: block;
} */
.terms-content h5 {
  font-size: 1.25rem;
  font-weight: 500;
  color: #212121;
  margin-bottom: 10px;
  margin-top: 10px;
}
.search-input-form .user-searchform button {
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 45%;
  right: 15px;
  font-size: 1.125rem;
  transform: translate(0px, -50%);
}
.search-input-form {
  position: relative;
}

.search-input-form ul {
  position: absolute;
  top: 40px;
  background: #ffffff;
  width: 100%;
  height: auto;
  z-index: 2;
  overflow: scroll;
  border: 1px solid #e8e3e357;
  max-height: 124px;
}
[darkmode] .search-input-form .user-searchform input {
  background: #252525;
  border: 2px solid #565656;
  color: #ffffff;
}

[darkmode] .search-input-form ul {
  color: #ffffff;
  background: #252525;
  border: 1px solid #565656;
}
.search-input-form ul li {
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #e8e3e357;
}
.sidebar-profile .sidebar-menu li a {
  position: relative;
  padding: 10px 10px 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--base-text-lightgray);
  text-transform: capitalize;
  transition: 0.3s ease-in-out;
  background-image: inherit;
}
.navbar-nav a.homeicon {
  font-size: 1.5rem;
  cursor: pointer;
}
.thredsbar .sidebar-profile .sidebar-menu li a.active,
.sidebar-profile .sidebar-menu li a:hover {
  color: #2fb84c;
}

input,
select,
textarea {
  outline: none;
}

.sidebar-profile .sidebar-menu li a svg {
  margin-right: 25px;
  font-size: 26px;
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sidebar-profile .sidebar-menu li a.active,
.sidebar-profile .sidebar-menu li a:hover {
  background-color: rgb(0 35 88 / 4%);
  background-image: url("../../../public/images/sidebar-menushape.png");
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}

.Whatsmind-bar {
  background: var(--base-purewhite);
  box-shadow: 0px 4px 24px rgba(204, 204, 204, 0.15);
  border-radius: 20px;
  padding: 5px;
}
.reply-post .whtsmind-btn-space {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 12px;
}
.whatsmind-inputbar .whatsmind-inputbar__highlighter {
  display: none;
}
.notification-text img {
  width: 50px;
  border-radius: 50%;
  height: 50px;
  object-fit: contain;
}
.rules-tag {
  cursor: pointer;
  margin-top: 5px;
  line-height: 1;
}
.user-active-timer {
  align-self: baseline;
}
/* 
.rules-sestion .modal-dialog {
  max-width: 460px !important;
} */

.rules-sestion .modal-dialog .modal-header {
  padding: 0 0 10px 0 !important;
  justify-content: flex-start;
}

.rules-sestion .modal-dialog .rule-section p {
  text-align: left !important;
  font-size: 1.125rem;
  position: relative;
  padding-left: 20px;
}

.rules-sestion .modal-dialog .modal-body {
  padding: 10px 0 0 0 !important;
}

.rules-sestion .modal-dialog .rule-section p::after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 5px;
  height: 5px;
  background: var(--base-primary) !important;
  border-radius: 50%;
}


.Whatsmind-bar form .word-note {
  margin-top: 5px;
}
.user-suggestion {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 5px;
  background: #fafafa;
}
.user-suggestion img {
  width: 28px;
  margin-right: 5px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
}
.useravail-text-align .rules-tag {
  margin: 0;
}
.whatsmind-inputbar__suggestions {
  margin-top: 0px !important;
  top: 40px !important;
  width: 30% !important;
  left: inherit !important;
  max-height: 150px !important;
  overflow: scroll !important;
}
ul.weekly-status li.listatus {
  text-align: left;
}
ul.weekly-status li.listatus h6 {
  font-size: .75rem;
}
ul.weekly-status li.listatus h4 {
  font-size: 1.125rem !important;
  line-height: 1 !important;
  margin-bottom: 5px !important;
}
.valuebar.up svg {
  transform: rotate(180deg);
}
.weekly-status li .numberbar {
  font-size: 1.25rem;
  color: #000000;
  margin: 0 !important;
}
.Whatsmind-bar form textarea, .whatsmind-inputbar__input {
  background: var(--base-lightgray);
  border-radius: 8px;
  padding: 15px;
  position: relative !important;
  border: 1px solid #aaaaaa;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem !important;
  line-height: 1.375rem;
  margin-bottom: 10px !important;
  height: 150px !important;
  overflow-y: scroll !important;
}
.whatsmind-inputbar__control .whatsmind-inputbar__input::placeholder{
  color: var(--base-secondry);
}
[darkmode] .whatsmind-inputbar__control .whatsmind-inputbar__input {
  color: var(--base-purewhite);
}
.whatsmind-inputbar__control .whatsmind-inputbar__input::placeholder {
  color: #6e7280bf;
}
.whatsmind-inputbar__control {
  margin-bottom: 10px;
}
.infotooltip ul {
  padding: 10px;
  list-style: dot;
}
.infotooltip ul li:last-child {
  margin-bottom: 0px;
}
.infotooltip ul li {
  position: relative;
  font-size: 0.875rem;
  text-align: left;
  padding-left: 10px;
  margin-bottom: 10px;
}
.infotooltip ul li::after {
  content: "";
  width: 3px;
  top: 10px;
  border-radius: 50px;
  height: 3px;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
}
.Whatsmind-bar form textarea::placeholder {
  color: var(--base-placeholder);
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.375rem;
}

.Whatsmind-bar form .word-note {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.063rem;
  color: var(--base-darkgray);
  margin-bottom: 0;
}

.digital-tabs {
  margin-top: 35px;
  position: relative;
  margin-bottom: 40px;
  border: none;
}

.digital-tabs li .nav-link,
ul.profiletabsbar li a {
  padding: 13px 60px;
  border: 1px solid var(--base-offgray);
  border-radius: 0;
  background: var(--base-offgray);
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.188rem;
  text-transform: capitalize;
  color: var(--base-offtextgray);
}

.profiletabsbar {
  margin: 60px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.digital-tabs li .nav-link.active,
ul.profiletabsbar li.active a {
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff !important;
  border-color: none !important;
  color: var(--tab-text) !important;
}

.digital-tabs li:first-child .nav-link,
ul.profiletabsbar li:first-child a {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.digital-tabs li:last-child .nav-link,
ul.profiletabsbar li:last-child a {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
[darkmode] .Uploaded-user p {
  color: #ffffff;
}
.digital-feeds {
  padding: 18px 25px;
  background: var(--base-purewhite);
  box-shadow: 0px 4px 24px rgba(204, 204, 204, 0.15);
  border-radius: 20px;
  margin-bottom: 20px;
}

.user-detail-bar {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0px;
  flex-wrap: wrap;
  position: relative;
}
.thredsbar .accordionitem .user-detail-bar {
  margin-top: 20px;
}
.viewmore {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  font-size: 1rem;
  cursor: pointer;
  color: #40a1db;
}

.user-detail-bar .detailleft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.user-detail-bar .detailleft .avatar-img.active::after {
  display: none;
  content: "";
  position: absolute;
  bottom: 0;
  right: 5px;
  width: 12px;
  height: 12px;
  background: var(--base-green);
  border-radius: 50%;
}

.user-detail-bar .detailleft .user-detail h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;
  margin-bottom: 2px;
}

.avatar-img img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.profile-user-detail .detailleft .user-availbility {
  margin: 0 !important;
}
.user-detail-bar .detailleft .user-detail .user-availbility {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.profile-user-detail .user-detail h4.text-secondry {
  height: 28px;
}

.user-detail-bar .detailleft .user-detail .user-availbility h6,
.user-detail-bar .detailleft .user-detail .user-availbility h5 {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.063rem;
  margin-bottom: 0;
}

.user-detail-bar .detailleft .user-detail .user-availbility h6 {
  margin-right: 5px;
}

.user-detail-bar .detailleft .user-detail .levelbar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: .75rem;
  line-height: .875rem;
  margin-top: 5px;
}

.user-detail-bar .detailleft .user-detail .levelbar h6 {
  margin-bottom: 0;
  width: 20px;
  height: 20px;
  background: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-left: 5px;
}

.user-detail-bar .detailleft .user-detail .levelbar h6 span {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--base-purewhite);
  border-radius: 50%;
  font-size: 7px;
}

.user-detail-bar .detailleft .awards-bar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 30px;
  border-radius: 25px;
  padding: 3px 0;
  background-color: transparent;
  position: relative;
  top: -10px;
}

.user-detail-bar .detailleft .awards-bar li {
  margin: 0 10px 0 0;
  display: flex;
  align-items: center;
}
.Uploaded-user {
  position: relative;
}

.Uploaded-user p {
  display: inline-block;
  margin: 0;
  position: absolute;
  left: 213px;
  top: 2px;
  z-index: 2;
  /* background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff !important; */
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  cursor: pointer;
}
.red-list {
  background: red;
}
.usertable table tbody tr td .sendbtn {
  text-align: right;
}

.usertable table tbody tr td .sendbtn .btn {
  width: auto;
  padding: 6px 10px;
  font-size: 0.875rem;
}
.profile-feature-image::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(281.05deg, #526eff00 -16.09%, #adbafd00 92.77%);
  box-shadow: inset 0px -96px 64px 2px rgb(0 0 0 / 28%);
}
.cursor-pointer {
  cursor: pointer;
}
.sidebar-profile .sidebar-menu li a svg path {
  stroke: #aaaaaa !important;
}

.sidebar-profile .sidebar-menu li a:hover svg path {
  stroke: #61bc53 !important;
}
.user-detail-bar .detailleft .awards-bar li img {
  margin-right: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
  width: 50px;
  border-radius: 50%;
  background: #9c9c9c;
  height: 50px;
  object-fit: cover;
}
.Auth-bar .Authbar-innerbox form .authinputbar p {
  position: absolute;
  top: 70%;
  right: 20px;
  font-size: 0.938rem;
  transform: translate(0px, -50%);
}
.formerrorset {
  position: relative;
}
.formerrorset .eyeset p {
  margin: 0;
  top: 50% !important;
  position: absolute;
  right: 20px;
  font-size: 0.938rem;
  transform: translate(0px, -50%);
}
.digital-feeds .user-active-timer ul li {
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.063rem;
}

.digital-feeds .user-active-timer ul li svg {
  margin-right: 5px;
  font-size: 1.5rem;
}
.Description-bar ul li{
  list-style: disc;
}
.Description-bar ul{
  padding-left: 2rem;
}

.digital-feeds .Description-bar {
  padding-bottom: 24px;
  overflow-wrap: break-word;
}
.digital-feeds .Description-bar p div {
  white-space: inherit !important;
}
.digital-feeds .Description-bar p div span {
  margin: 0 5px 0 0;
}
t .report-tabs li.active {
  background: var(--base-primary);
  border-color: var(--base-primary);
  color: var(--base-purewhite);
}

.digital-feeds .Description-bar p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.875rem;
  margin-bottom: 0;
  word-break: break-word;
}

.action-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0 15px 0;
  flex-wrap: wrap;
  border-style: solid;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 0 !important;
  border: 3px solid;
  border-image-slice: 1 !important;
  border-width: 2px;
  border-image-source: linear-gradient(to left, #98d185, #40adc3) !important;
}

.action-bar ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  column-gap: 10px;
}

.action-bar ul li,
.action-bar ul li .accordion-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  color: var(--base-text-lightgray);
  cursor: pointer;
  margin-bottom: 5px;
}
.action-bar ul li p {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  color: var(--base-text-lightgray);
  cursor: pointer;
  margin-bottom: 0px;
}
.action-bar ul li .accordion-button {
  margin: 0;
}
.digital-feeds .digital-feeds {
  margin-bottom: 0;
}
.digital-feeds .thredsbar span.no-comment {
  margin-top: 0 !important;
}

.action-bar ul li:first-child {
  margin-left: 0;
}

.action-bar ul li svg {
  margin-right: 6px;
  font-size: 32px;
  transition: 0.3s ease;
}
.share-popup .modal-body {
  height: auto !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.copiedbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.copiedbar .copytext {
  padding: 0;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 30px;
}
.copiedbar .content {
  font-size: 14px;
  margin-bottom: 0;
  color: white;
}
  .modal-content .input-group-text{
    color: white;
    background: var(--base-primary);
  }
  .welcomepopup .modal-header{
    column-gap: 20px;
  }
  .modal-content{
    border-radius: 20px;
  }
.copiedbar svg {
  top: 20px !important;
}
.action-bar ul li span.number {
  margin-right: 5px;
}

.action-bar ul li .active svg {
  color: #2fb84c;
}

.action-bar ul li.active {
  color: #2fb84c;
}
.action-bar ul li:hover .list-text {
  color: #5fba4e;
}
[darkmode] .digital-feeds .Description-bar a:hover {
  color: var(--darkmode-offwhite);
}
.Dropdown-listing.notification-dropdown .notification-content .levelbar {
  top: -8px !important;
}
.hoverdropdown .dropdown-menu .dropdown-item svg {
  margin-right: 10px;
  font-size: 18px;
}
.action-bar ul li.active {
  color: #2fb84c;
}

.hoverdropdown button::after {
  display: none;
}

.user-detail-bar .detailleft .user-detail h4.text-secondry,
.user-detail-listing li p {
  cursor: pointer;
}

.hoverdropdown button:hover {
  color: var(--base-primary) !important;
}

.hoverdropdown .dropdown-menu {
  min-width: 220px;
  padding: 5px 0px;
  margin: 0;
  background: var(--base-purewhite);
  border: 1px solid #dddde3;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.hoverdropdown .dropdown-menu .dropdown-item {
  padding: 5px 10px;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  color: var(--base-secondry);
}

.hoverdropdown .dropdown-menu .dropdown-item.disabled {
  opacity: 0.3;
}

.hoverdropdown .dropdown-menu .dropdown-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hoverdropdown .dropdown-menu .dropdown-item p {
  margin: 0;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  color: var(--base-secondry);
}

.hoverdropdown .dropdown-menu .dropdown-item span {
  font-size: .75rem;
  line-height: 1.188rem;
}
.Dropdown-listing .arrowbar {
  height: 300px;
  padding: 20px 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.Dropdown-listing .arrowbar .notification-content .notification-text p {
  font-size: 0.875rem !important;
}

.Dropdown-listing .arrowbar .notification-content .levelbar {
  top: -16px !important;
  margin: 0 !important;
}

.Dropdown-listing .arrowbar .notification-content .user-detail-bar {
  margin-top: 0px !important;
  max-height: 55px;
}
.hoverdropdown .dropdown-menu .dropdown-item span svg {
  margin-right: 2px;
}

.hoverdropdown .dropdown-menu .dropdown-item:last-child {
  margin: 0;
}

.hoverdropdown .dropdown-menu .dropdown-item svg {
  margin-right: 10px;
}

.user-edit {
  margin-top: 30px;
}

.user-edit h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--base-whitesure);
  margin-bottom: 10px;
}

.user-edit h4 svg {
  margin-right: 10px;
}

.user-edit textarea.form-control,
.user-edit input {
  background: #f8f8f8;
  border-radius: 8px;
  padding: 20px 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.625rem;
  color: var(--base-default-text);
  border: none;
  min-height: auto !important;
  margin-bottom: 27px;
}

.outline-primary,
.btn-outline-primary {
  background: transparent !important;
  color: var(--base-primary) !important;
  border: 1px solid var(--base-primary) !important;
}

.user-edit button svg {
  margin-right: 10px;
  font-size: 0.875rem;
}

.user-edit button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  min-width: 160px;
  padding: 10px 38px;
}

.hoverdropdown button {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.188rem;
  background: transparent !important;
  color: var(--base-text-lightgray);
  cursor: pointer;
}

.accordion {
  border: none;
  box-shadow: none;
}

.accordion .accordion-item {
  border: none;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
}

.actionleftbar .accordion-header button.accordion-button {
  padding: 0;
  background: transparent;
  border: none;
  color: var(--base-text-lightgray);
  box-shadow: none;
}

.actionleftbar .accordion-header button.accordion-button::after {
  display: none;
}

.accordion .digital-feeds {
  margin-bottom: 0;
}

.modal-header {
  padding: 0px 20px 20px 20px;
  margin-bottom: 10px;
}

.retweetmodal .digital-feeds.feed-type .feedtype-textarea textarea {
  background: #f8f8f8;
  border-radius: 8px;
  padding: 15px;
}

.thredsbar span.no-comment {
  margin-top: 15px !important;
  display: block;
}

.retweetmodal .digital-feeds.diffrentiate-bar {
  margin-left: 50px;
}

.retweetmodal .digital-feeds.diffrentiate-bar .Description-bar {
  padding: 5px 0 0 0;
}

.retweetmodal .digital-feeds.diffrentiate-bar .Description-bar p {
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.thredsbar span.no-comment ~ .reply-post {
  margin-top: 10px;
}
[darkmode] .reply-post {
  background: #272728;
  border: 1px solid #383838;
}
.accordion .accordion-collapse {
  margin-top: 0px;
  background: var(--base-purewhite);
  box-shadow: 0px 4px 24px rgba(204, 204, 204, 0.15);
  border-radius: 20px !important;
}

.actionleftbar .accordion-header button.accordion-button:not(.collapsed) {
  color: var(--base-primary);
}
.typefilenone input[type="file"] {
  display: none;
}
.typefilenone {
  opacity: 0.5;
}
.thredsbar {
  padding: 20px 50px;
  position: relative;
}
.thredsbar .thredsbar {
  overflow: visible;
  height: auto;
}
.thredsbar h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--base-secondry);
  margin-bottom: 20px;
}

.user-detail-bar .detailleft .follow-bar {
  display: flex;
  align-items: center;
  column-gap: 20px;
  justify-content: flex-start;
}

.followbtn {
  /* margin-left: 20px; */
  border-radius: 3.57692px;
  padding: 6px 16px;
  background: transparent;
  font-style: normal;
  font-weight: 700;
  font-size: .75rem;
  line-height: .875rem;
  color: #60c1a3;
  transition: 0.3s ease;
  min-width: 92px;
  border-image-width: 1;
  border-image-source: linear-gradient(rgb(61 179 190), rgb(144 208 137));
  border-width: 2px;
  border-image-slice: 1;
}

.followbtn:hover {
}

.user-detail-bar .detailleft .user-detail .user-availbility h5 {
  position: relative;
  padding-left: 8px;
}

.user-detail-bar .detailleft .user-detail .user-availbility h5::after {
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 50px;
  background: var(--base-text-lightgray);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0px, 0px);
}

/* .user-detail-bar .detailleft .user-detail .user-availbility h5.redtime::after {
  background: red;
} */

/* .user-detail-bar .detailleft .user-detail .user-availbility h5.greentime::after {
    background: var(--base-green);
} */
.reply-post {
  margin-top: 10px;
}

.reply-post form {
  display: flex;
  align-items: center;
  position: relative;
}
.welcomepopup .modal-header {
  justify-content: space-between !important;
  padding: 0 30px 20px 0;
}

.welcomepopup .welcomepopupbar {
  text-align: center;
}
.welcomepopup .welcomepopupbar p span {
  color: #21a0da;
}
.welcomepopup .welcomepopupbar p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.875rem;
  /* or 167% */
  text-align: center;
  color: #545456;
  margin-bottom: 5px;
}
.welcomepopup .modal-body {
  padding: 20px 0 !important;
  height: auto !important;
  overflow: hidden !important;
}
.treeicon img {
  width: 50px;
}
[darkmode] .treeicon img {
  filter: invert(1);
}
.editor .ql-editor a {
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #9dd382;
}
.welcomepopup .welcomepopupbar p b {
  color: #171717;
}
[darkmode] .welcomepopup .welcomepopupbar p b,
[darkmode] .welcomepopup .welcomepopupbar h4 {
  color: #ffffff;
}
.welcomepopup .welcomepopupbar h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  /* identical to box height */
  text-align: center;
  margin-top: 30px;
  color: #171717;
  margin-bottom: 20px;
}
.usermain-img .avatar_img{
  min-width: 142px;
  border-radius: 50% !important;
  border: 6px solid transparent;
  background: linear-gradient(rgba(62, 172, 198, 1), rgba(157, 211, 131, 1)) padding-box,
  linear-gradient(to bottom, rgba(62, 172, 198, 1), rgba(157, 211, 131, 1)) border-box;
}
.usermain-img .avatar_img img{
  border: none !important;
}
.avatar-img img{
  border: none !important;
}


.welcomepopup .welcomepopupbar button.btnprimary {
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  border-radius: 5px;
  margin-top: 30px;
  margin-bottom: 10px;
  border: none;
  padding: 13px 22px;
  cursor: pointer;
}
.reply-post form .replyinput {
  width: 100%;
  margin-bottom: 10px;
}

.reply-post form .replyinput .form-control {
  background: #f8f8f8;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  padding: 20px 90px 20px 20px !important;
  font-weight: 400;
  font-size: 1.125rem;
  height: 70px;
  overflow-x: hidden;
  overflow-y: scroll;
  line-height: 1.375rem;
}

.Notfy-block {
  background: var(--base-purewhite);
  box-shadow: 0px 4px 24px rgb(204 204 204 / 15%);
  border-radius: 20px;
  padding: 30px 30px;
}
.reply-post p.counter-text {
  position: absolute;
  bottom: 12px;
  margin: 0;
  right: 100px;
  font-size: 14px;
}
.reply-post form .btn {
  position: relative;
  right: inherit;
  padding: 0;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  font-weight: 700;
  line-height: 1.625rem;
  font-size: 1.5rem;
  letter-spacing: 0.02em;
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  top: inherit;
}
.ql-toolbar.ql-snow .ql-formats{
  margin-right: 0 !important;
}
.action-bar .reply-post {
  margin: 0;
  width: 100%;
  margin-top: 20px;
  display: none;
}

.action-bar .reply-post.active {
  display: block;
}

.reply-post form .replyinput .form-control::placeholder {
  color: var(--base-placeholder);
}

.thredsbar .threads-rows {
  padding-left: 80px;
  position: relative;
}

.thredsbar .threads-rows .thredsbar-inner {
  padding-left: 0px;
  padding-bottom: 0;
  padding-right: 0;
}

.thredsbar .threads-rows .accordion-collapse {
  box-shadow: none;
  margin: 0;
  border-radius: 0;
  background: transparent;
}

.thredsbar .threads-rows::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 100%;
  left: 30px;
  top: 0;
  background: #c5c5c5;
}
[darkmode] .thredsbar .threads-rows::after {
  background: #6c6c6c;
}
.thredsbar .threads-rows::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 16.8px;
  background-image: url("../../../public/images/carotdown.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 29px;
  width: 29px;
  height: 18px;
  transform: translate(0px, -50%);
}

.user-detaiting-listing {
  margin-bottom: 15px;
}

.user-detaiting-listing li {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  word-break: break-word;
  color: var(--base-text-lightgray);
}

.user-detaiting-listing li img {
  margin-left: 10px;
}

.Actions-modal .modal-header .modal-title.h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1.625rem;
  color: #404040;
}

.Actions-modal .modal-content {
  padding: 20px 30px;
  border: none;
}
.retweetmodal.modal-body .user-preview {
  position: relative;
}
.retweetmodal.modal-body .user-preview .Description-bar {
  padding: 0;
}
.retweetmodal.modal-body .digital-feeds.feed-type {
  padding-top: 15px;
}
.Whatsmind-bar p.counter-text {
  float: left;
  display: inline-flex;
  padding: 30px 20px;
  font-weight: 500;
  margin: 0px !important;
}
.retweetmodal.modal-body .user-preview .Description-bar p.counter-text {
  padding: 10px 0;
  font-size: 18px;
  line-height: 1;
}
.ql-editor.ql-blank::before {
  font-style: normal;
  font-size: 16px;
}
.reply-post .reply-fix-bottom .ql-editor::before {
  font-style: normal;
  font-size: 14px;
}
.retweetmodal.modal-body .user-preview .Description-bar .editor {
  border: 1px solid lightgrey;
  border-radius: 12px;
}
.Actions-modal .modal-header button.btn-close {
  opacity: 1;
  font-size: 1rem;
  position: absolute;

  right: 2rem;
  cursor: pointer;
  transition: 0.3s ease;
}

/* .Actions-modal .modal-header button.btn-close:hover {
    transform: translate(0,-50%) rotate(360deg) !important;
} */
.Actions-modal .modal-body {
  padding: 20px;
  height: 380px;
  overflow-y: scroll;
  border: none;
}

.viewpostawardsmodal .awards-bar {
  padding: 0 !important;
  top: 0 !important;
  flex-wrap: wrap;
  display: flex;
  justify-content: center !important;
  margin-left: 0 !important;
}

.viewpostawardsmodal .awards-bar li img {
  width: 100px !important;
  display: block;
  height: 100px;
  margin: 0 auto 10px auto !important;
}

.viewmorebtn {
  padding: 0;
  border: none;
  background: transparent;
  font-size: 1rem;
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  cursor: pointer;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.viewpostawardsmodal .user-detail-bar {
  padding: 0;
  margin: 0;
}

.viewpostawardsmodal .awards-bar li {
  flex: 0 0 21%;
  max-width: 21%;
  display: block !important;
  margin: 0 10px 20px 10px !important;
  border-radius: 10px;
  box-shadow: 0px 10px 30px #0000000d;
  padding: 15px;
  text-align: center;
  font-size: 1.25rem;
}

.Actions-modal .modal-body .aggree-li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.Actions-modal .modal-body .aggree-li li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Actions-modal .modal-body .aggree-li li img {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-right: 15px;
}

.Actions-modal .modal-body .aggree-li li .user-del h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--base-secondry);
  margin: 0;
}

.Actions-modal .modal-body .aggree-li li .user-del h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: var(--base-text-lightgray);
  margin-bottom: 0;
}

.Actions-modal .Awrds-li {
  text-align: center;
  margin-bottom: 20px;
}

.Actions-modal .Awrds-li img {
  margin-bottom: 20px;
  width: 100px;
}

/* .Actions-modal .Awrds-li h4 {
  margin: 0;
  background: var(--base-lightgray);
  border: 0.2px solid var(--base-border);
  border-radius: 40px;
  padding: 15px 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.375rem;
  display: inline-block;
  color: var(--base-text-lightgray);
} */
.Actions-modal .Awrds-li h4 {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.375rem;
  display: block;
  color: #000000;
}
.user-detail-listing li:last-child {
    margin: 0;
}
.Actions-modal .Awrds-li button {
  border: none;
  padding: 7px 16px;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.awards-modal .modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-right: 40px;
}

.modal-footer {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
}

.awards-modal .modal-header button.btn-close {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0px, -50%);
}

.awards-modal .modal-header button.btn-add {
  padding: 14px 20px;
  height: auto;
  background: var(--base-lightgray);
  border: 0.2px solid var(--base-border);
  border-radius: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--base-secondry);
}

.levelbar {
  display: inline-flex !important;
  position: relative;
}

.awards-modal .modal-header button.btn-add svg {
  margin-left: 10px;
}

.empty-bar {
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.empty-bar h4 {
  font-size: 1.5rem;
  margin: 0;
}

.z-1050.modal.show {
  z-index: 1060;
}

/* .fade.modal-backdrop {
  background: #000000 !important;
  z-index: 9999;
} */

.award_of_package {
  cursor: pointer;
}

.buymore-modal .Awrds-li {
  position: relative;
  border: 1px solid #dddde3;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12),
    0px 20px 20px 2px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 30px 10px 15px 10px;
  text-align: left;
}

.buymore-modal .Awrds-li p.Begde-bar {
  position: absolute;
  top: 5px;
  right: 10px;
  margin: 0;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--base-text-lightgray);
}

.buymore-modal .Awrds-li h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--base-text-lightgray);
}

.buymore-modal .modal-dialog {
  max-width: 772px;
}

.buymore-modal .Awrds-li .awards-img {
  text-align: center;
  position: relative;
}

.buymore-modal .Awrds-li .awards-img p {
  text-align: right;
  position: absolute;
  right: 0;
  top: -20px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.375rem;
  color: #aaaaaa;
  font-size: 0.875rem;
  margin: 0;
}

.buymore-modal .Awrds-li h3 {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 29px;
  color: var(--base-secondry);
}
[darkmode] .buymore-modal .Awrds-li h3 {
    color: #ffffff;
}
.buymore-modal .modal-body {
  padding: 20px 20px;
}

.report-tabs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
}

.report-tabs li {
  background: var(--base-purewhite);
  border: 2px solid #dddde3;
  border-radius: 20px;
  padding: 9px 22px;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;
  color: var(--base-text-lightgray);
  text-transform: capitalize;
  margin-bottom: 10px;
  margin-right: 8px;
}

.Editreportmodal form label.form-label {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--base-secondry);
  display: block;
  text-align: left;
}

.Editreportmodal form textarea {
  background: var(--base-purewhite);
  border: 1px solid #dddde3;
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;
  color: #000000;
  padding: 14px 20px;
}

.Editreportmodal form textarea::placeholder {
  color: var(--base-text-lightgray);
}

.Editreportmodal form {
  text-align: right;
}

.share-media {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px 20px 20px;
  margin: 0 -40px;
}

.share-media li .sharebtn {
  width: 76px;
  height: 76px;
  background: rgba(34, 34, 34, 0.06);
  border-radius: 50%;
  color: #222222;
  border: 1px solid rgba(34, 34, 34, 0.06);
  transition: 0.5s ease;
  margin-bottom: 10px;
}

.deletemodal .modal-body {
  height: auto;
  padding: 30px 20px;
}

.share-media li {
  padding: 0 20px;
  text-align: center;
}

.header-nav .navbar-nav .user-dropdown img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.modal.show .modal-dialog {
  max-width: 772px;
}

.share-media li .sharebtn svg {
  font-size: 26px;
  transition: 0.3s ease;
  color: #222222;
}

.share-media li p {
  font-weight: 400;
  font-size: 14.22px;
  line-height: 1.063rem;
  text-align: center;
  color: var(--base-text-lightgray);
  margin: 0;
}

.share-media li:hover .sharebtn {
  transform: rotate(360deg);
}

.share-media li .sharebtn {
  width: 76px;
  height: 76px;
  background: rgba(34, 34, 34, 0.06);
  border-radius: 50%;
  color: #222222;
  border: 1px solid rgba(34, 34, 34, 0.06);
  transition: 0.5s ease;
  margin-bottom: 10px;
}

.share-media li {
  padding: 0 20px;
  text-align: center;
}

.modal.show .modal-dialog {
  max-width: 772px;
}

.share-media li .sharebtn svg {
  font-size: 26px;
  transition: 0.3s ease;
  color: #222222;
}

.share-media li p {
  font-weight: 400;
  font-size: 14.22px;
  line-height: 1.063rem;
  text-align: center;
  color: var(--base-text-lightgray);
  margin: 0;
}

.share-media li:hover .sharebtn {
  transform: rotate(360deg);
}

.share-popup form input.form-control {
}

.share-popup form {
  position: relative;
}

.share-popup form button {
  background: transparent !important;
  border: none !important;
  padding: 5px !important;
  color: var(--base-text-lightgray);
  position: absolute;
  top: 7px;
  right: 15px;
  font-size: 26px;
}

.notification-dropdown {
  width: 400px !important;
}

.notification-dropdown .notification-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 0.557878px solid var(--base-border);
  margin-bottom: 20px;
}

.notification-dropdown .notification-head h4 {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 1.125rem !important;
  line-height: 1.375rem !important;
  color: var(--base-primary);
  margin: 0 !important;
}

.notification-dropdown .notification-head h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: var(--base-primary);
  margin: 0;
}

.notification-list {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 0.557878px solid var(--base-border);
}

.notification-list ul {
  display: flex;
  align-items: flex-start;
  margin-bottom: 7px;
}

.notification-list ul li {
  margin: 0 !important;
}

.notification-list ul li img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  object-fit: contain;
  border-radius: 50%;
}

.notification-list ul li:last-child {
  display: flex;
  align-items: flex-start;
  justify-content: space-between !important;
  width: 100%;
}

.notification-list ul li:last-child .userdetail {
  margin-left: 10px;
}

.notification-list ul li:last-child .userdetail h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 0.938rem;
  color: var(--base-secondry);
  margin: 0;
}

.notification-list ul li:last-child .userdetail p {
  font-style: normal;
  font-weight: 400;
  font-size: 0.563rem;
  line-height: 0.75rem;
  color: var(--base-text-lightgray);
  margin: 0;
}

.notification-list p.notification {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.313rem;
  color: var(--base-default-text);
}

.notification-list ul li p.times {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 0.688rem;
  line-height: 1.313rem;
  color: var(--base-default-text);
}

.notification-dropdown .notification-foot {
  text-align: center;
}

.notification-dropdown .notification-foot button,
.notification-dropdown .notification-foot a {
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--base-primary);
  border: none !important;
  background: transparent !important;
  cursor: pointer;
}

.notification-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--base-border);
  margin-bottom: 30px;
}

.notification-title ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.notification-title ul li {
  margin-left: 20px;
}

.notification-title ul li .btn-nill {
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.188rem;
  color: var(--base-primary);
  border: none;
  background: transparent;
  cursor: pointer;
}

.notification-content {
  padding-bottom: 20px;
  border-bottom: 1px solid var(--base-border);
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: var(--darkmode-offwhite);
  margin-bottom: 20px;
}

.notification-content p.notify {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.875rem;
  color: var(--darkmode-offwhite);
  cursor: pointer;
}

.notification-content .user-active-timer li.text-green {
  color: #000000;
  cursor: pointer;
}

body[data-page="/profile"] .sidebar-menu,
body[data-page="/editprofile"] .sidebar-menu {
  display: none;
}

body[data-page="/profile"] .mainwrapper,
body[data-page="/editprofile"] .mainwrapper {
  width: 100% !important;
}

body[data-page="/profile"] .mainwrapper,
body[data-page="/editprofile"] .mainwrapper {
  width: 100% !important;
  padding: 0 0;
}

body[data-page="/profile"] .body-wrapper,
body[data-page="/editprofile"] .body-wrapper {
  padding: 0 0;
}

body[data-page="/profile"] .somewhere-full,
body[data-page="/editprofile"] .somewhere-full {
  padding: 0 0;
}

body[data-page="/profile"] .somewhere-full .somewhere-pad-0,
body[data-page="/editprofile"] .somewhere-full .somewhere-pad-0 {
  margin: 0 0;
  padding: 0;
}

.profile-feature-image {
  height: 422px;
  overflow: hidden;
}

.profile-feature-image img {
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.action-bar ul li.awardsbtn-disable{
  display: none;
}

.profile-user-detail {
  margin-top: 30px;
}

.profile-user-detail .user-detail-bar .detailleft .avatar-img {
  width: 350px;
  height: 350px;
  border-radius: 50% !important;
  border: 6px solid transparent;
  background: linear-gradient(rgba(62, 172, 198, 1), rgba(157, 211, 131, 1)) padding-box,
  linear-gradient(to bottom, rgba(62, 172, 198, 1), rgba(157, 211, 131, 1)) border-box;}

.profile-user-detail .user-detail-bar .detailleft .avatar-img img {
  width: 100%;
  height: 100%;
}

.user-detail-bar .detailleft .avatar-img {
  position: relative;
  margin-right: 18px;
  margin-bottom: 18px;
}

.profile-user-detail
  .user-detail-bar
  .detailleft
  .user-detail
  h4.text-secondry {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  margin-bottom: 15px;
}

.profile-user-detail
  .user-detail-bar
  .detailleft
  .user-detail
  .user-availbility
  h6.text-lightgray {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.875rem;
}
.text_level{
  margin-top: 24px;
  column-gap: 18px;
}
.profile-user-detail .user-detail-bar .detailleft .user-detail .levelbar {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 0.938rem;
  color: #939d9e;
}

.profile-user-detail .user-edit-cover ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.profile-user-detail .user-edit-cover ul li {
  margin-bottom: 25px;
}
.green-button{
  font-style: normal;
  font-weight: 600;
  font-size: 15.5965px;
  line-height: 1.375rem;
  color: var(--base-green);
  border: 1px solid var(--base-green);
  border-radius: 5px;
  padding: 9px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  overflow: hidden;
  background: var(--base-purewhite);
}
.green-button:hover{
  background: var(--base-primary)!important;
  color: var(--base-purewhite);
  text-decoration: none !important;
}


.profile-user-detail .user-edit-cover ul li button {
  background: rgb(0 0 0 / 30%);
  font-style: normal;
  font-weight: 600;
  font-size: 18.5965px;
  line-height: 1.375rem;
  color: var(--base-purewhite);
  border-radius: 5px;
  padding: 14px 32px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  overflow: hidden;
}
.profile-feature-image {
  position: relative;
}

.user-edit-cover .savebtn {
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff !important;
  color: var(--tab-text) !important;
  height: auto;
  padding: 13px 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.188rem;
  text-transform: capitalize;
  border: none !important;
  border-radius: 5px;
}
.profile-user-detail .user-edit-cover ul li button input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.profile-user-detail .user-edit-cover ul li a svg,
.profile-user-detail .user-edit-cover ul li button svg {
  margin-right: 10px;
}

.profile-user-detail .user-edit-cover ul li a:hover,
.profile-user-detail .user-edit-cover ul li button:hover {
  background: #000000;
}

.profile-user-detail .user-detail-listing {
  margin-top: 15px;
  gap: 20px;
  flex-wrap: wrap;
}

.notification-content.readmark p.notify {
  color: var(--darkmode-offwhite);
}

.notification-content.unreadmark p.notify {
  color: #000000;
  font-weight: 400;
}

.aboutline {
  margin-bottom: 40px;
}

.aboutline h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--base-secondry);
  margin-bottom: 10px;
}

.aboutline p {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.875rem;
  color: var(--base-default-text);
}

.Uploaded-user .Imagebar {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 10px solid var(--base-purewhite);
  position: relative;
}

.Uploaded-user .Imagebar img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.Uploaded-user .Imagebar .userup-in {
  width: 51px;
  height: 51px;
  background: #36afe2;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 30px;
  color: var(--base-purewhite);
  position: absolute;
  right: 25px;
  bottom: 0px;
}

.Uploaded-user .Imagebar .userup-in .typefile input[type="file"] {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  z-index: 2;
}

.Uploaded-user .Imagebar .userup-in .typefile {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.Uploaded-user .Imagebar .userup-in .typefile svg {
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.Profile-Upload-media .save-form-btn button svg {
  font-size: 1rem;
}

.Profile-Upload-media .save-form-btn button svg {
  font-size: 1rem;
}

.editor-input {
  margin-bottom: 30px;
}

.editor-input .form-label {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--base-secondry);
  margin-bottom: 15px;
}

.editor-input .form-control {
  background: var(--base-purewhite);
  border: 2px solid #d9d9d9;
  border-radius: 8px;
  height: auto;
  padding: 18px 24px;
}

.Userdetail-editorbar {
  margin-top: 50px;
}

.Userdetail-editorbar {
  margin-top: 50px;
  margin-bottom: 50px;
}

.account-verify label.form-label {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--base-secondry);
  margin-bottom: 15px;
}

.account-verify .verify-input {
  display: flex;
  align-items: center;
}

.account-verify .verify-input input.form-control {
  padding: 18px 24px;
  margin-right: 30px;
  max-width: 73%;
}

.account-verify .verify-input button {
  padding: 18px 30px;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--base-primary);
}

.account-verify .verify-input button.outline-primary {
  padding: 0;
  border: none !important;
  background-color: transparent !important;
}

.account-verify .verify-input button.outline-primary:hover {
  color: var(--base-primary) !important;
}
/* 
.upload-media-verify {
  background: rgba(0, 35, 88, 0.08);
  border: 1px dashed var(--base-primary);
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  min-height: 128px;
} */
.upload-media-verify {
  background: transparent;
  border: none;
  border-radius: 8px;
  padding: 0;
  text-align: left;
  min-height: auto;
  margin-bottom: 10px;
  margin-top: 10px;
}
.upload-media-verify button {
  position: relative;
  min-width: 45%;
}
[darkmode] .upload-media-verify,
[darkmode] .imagebarupload {
  background: transparent !important;
}
.upload-media-verify button input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  opacity: 0;
}

.editor-input .form-select {
  background: var(--base-purewhite);
  border: 2px solid #d9d9d9;
  border-radius: 8px;
  height: auto;
  padding: 18px 24px;
  appearance: revert;
}

.passwrd-verify .resetbtn {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
  color: var(--base-primary) !important;
}

.Auth-bar {
  height:auto;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-image: url("../../../public/images/auth-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Auth-bar .Authbar-innerbox {
  background: var(--base-purewhite);
  border: 0.5px solid #e8e8e8;
  box-shadow: 8px 9px 31px rgba(84, 114, 247, 0.09);
  border-radius: 30px;
  max-width: 563px;
  margin: 0 auto;
  padding: 40px;
}

.Auth-bar .Authbar-innerbox h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 1.75rem;
  display: flex;
  align-items: center;
  color: var(--base-primary);
  justify-content: center;
}

.Auth-bar .Authbar-innerbox p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #000000;
  text-align: center;
}

.Auth-bar .Authbar-innerbox form {
  margin-top: 25px;
  position: relative;
}

.Auth-bar .Authbar-innerbox form .authinputbar {
  position: relative;
  margin-bottom: 20px;
}

.Auth-bar .Authbar-innerbox form .authinputbar label.form-label {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--base-offwhite);
  margin-bottom: 8px;
  text-transform: capitalize;
}

.Auth-bar .Authbar-innerbox form .authcheckbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Auth-bar
  .Authbar-innerbox
  form
  .authcheckbox
  .form-check-input[type="checkbox"] {
  margin: 0 10px 0 0;
  float: none !important;
  width: 24px;
  height: 24px;
  position: relative;
}
.dashboard-title-bar .sendbtn {
  margin-right: 10px;
}
.notification-dropdown .user-detail-bar .user-active-timer li,
.notification-dropdown .user-detail-bar .user-active-timer li svg {
  margin: 0 !important;
}
.notification-dropdown .user-detail-bar .detailleft .avatar-img {
  margin-right: 10px;
}
.notification-dropdown .user-detail-bar .detailleft .avatar-img img {
  width: 36px;
  height: 36px;
}
[darkmode] .helpicon svg {
  color: #ffffff;
}
.notification-come {
  position: relative;
}
.editor-same-line .imagebarupload {
  max-width: 570px;
}

.editor-same-line p.uploaded-note {
  max-width: 570px;
}

.editor-same-line .imagebarupload .verify-user-avatar img {
  position: relative !important;
  display: block !important;
  width: 60px;
  margin-left: 20px;
}
.aboutdygres a {
  text-decoration: underline;
}
.terms-condition.aboutdygres {
  margin-top: 6px !important;
}
.uplaod-user-verify {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.sidebar-profile .sidebar-menu li.svggray:hover svg path {
  fill: #61bc53 !important;
}
.sidebar-profile .sidebar-menu li.svggray svg path {
  fill: #aaaaaa !important;
}
.uplaod-user-verify .verify-user-avatar img {
  width: 50px;
  margin-left: 20px;
  height: 50px;
  object-fit: contain;
}
.notification-come::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: red;
  position: absolute;
  top: 27px;
  right: 0px;
}
.table-toggle-checkbox {
  padding: 0;
  text-align: left;
}
.notification-dropdown .notification-content {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.notification-dropdown
  .user-detail-bar
  .detailleft
  .user-availbility
  h5.text-lightgray.greentime {
  display: none;
}
.table-toggle-checkbox .form-check-input {
  width: 34px;
  margin: 0 !important;
  position: relative;
}
.dashboard-title-bar .sendbtn a {
  padding: 14px 10px !important;
  display: block;
  text-align: center;
}
.redbtn {
  padding: 12px 50px;
  border-radius: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 18.5965px;
  line-height: 1.375rem;
  transition: 0.3s ease-in-out;
  border: none;
  cursor: pointer;
}
.btn-danger[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.Auth-bar .Authbar-innerbox form .authcheckbox .form-check {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Auth-bar
  .Authbar-innerbox
  form
  .authcheckbox
  .form-check
  label.form-check-label {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #222446;
}

.Auth-bar .Authbar-innerbox form .authcheckbox .btn-simple a {
  text-decoration: none;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  display: inline-block;
  text-align: right;
  color: var(--base-primary);
}

.Auth-bar .Authbar-innerbox form button[type="submit"],
.Auth-bar .Authbar-innerbox form a.btn {
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  height: auto;
  display: block;
}

.Auth-bar .Authbar-innerbox form .Noted-bar {
  margin-top: 20px;
  text-align: center;
}

.Auth-bar .Authbar-innerbox form .Noted-bar h6 {
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--base-offwhite);
  margin: 0;
}

.Auth-bar .Authbar-innerbox form .Noted-bar h6 a {
  color: var(--base-primary);
  text-decoration: none;
}

.Auth-bar .Authbar-innerbox form .terms-condition {
  text-align: center;
  margin-top: 30px;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--base-primary);
}

.Adminlayout {
  padding: 50px 0 90px 0;
}

.dashboard-title-bar h4 {
  font-weight: 600;
  font-size: 2.875;
  line-height: 3.625;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  margin: 0;
}

.dashboard-title-bar select.form-select {
  margin: 0 0 0 auto;
  background: var(--base-purewhite);
  border: 1.37244px solid rgba(133, 134, 136, 0.16);
  border-radius: 8px;
  appearance: revert;
  padding: 16px 50px 16px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 1.313rem;
  line-height: 1.563rem;
  color: #858688;
  appearance: none;
  background-image: url("../../../public/images/arrowdown.png");
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 1em;
  width: 172px;
}

.dashboard-title-bar {
  margin-bottom: 50px;
}

.dashboard-boxes .dash-boxes-in {
  padding: 22px 28px;
  background: var(--base-purewhite);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12),
    0px 12px 20px 8px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  margin-bottom: 30px;
  display: block;
}

.dashboard-boxes .dash-boxes-in ul.userstatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dashboard-boxes .dash-boxes-in ul.userstatus li {
  display: flex;
  align-items: center;
}

.dashboard-boxes .dash-boxes-in ul.userstatus li .iconbar {
  background: #f0f0f0;
  border-radius: 14.065px;
  width: 56.26px;
  height: 56.26px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  color: var(--base-primary);
  margin-right: 20px;
  transition: 0.5s ease;
}

.dashboard-boxes .dash-boxes-in ul.userstatus li h5 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--base-inputautofill);
}

.dashboard-boxes .dash-boxes-in ul.userstatus li svg {
  font-size: 32px;
  color: var(--base-primary);
  transition: 0.5s ease;
}

.dashboard-boxes .dash-boxes-in ul.weekly-status {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.dashboard-boxes .dash-boxes-in ul.weekly-status li h4 {
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 2.125rem;
  line-height: 2.563rem;
  color: var(--base-secondry);
}

.dashboard-boxes .dash-boxes-in ul.weekly-status .valuebar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-boxes .dash-boxes-in ul.weekly-status .valuebar p {
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.688rem;
  margin: 0 0 0 8px;
}

.dashboard-boxes .dash-boxes-in ul.weekly-status .valuebar svg {
  font-size: 26px;
}

.dashboard-boxes .dash-boxes-in ul.weekly-status .valuebar.up svg,
.dashboard-boxes .dash-boxes-in ul.weekly-status .valuebar.up p {
  color: #0caf60;
}

.dashboard-boxes .dash-boxes-in ul.weekly-status .valuebar.down svg,
.dashboard-boxes .dash-boxes-in ul.weekly-status .valuebar.down p {
  color: #fa5f1c;
}

.dashboard-boxes .dash-boxes-in ul.weekly-status h5 {
  margin: 8px 0 0 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 1.688rem;
  color: #4c4d4f;
}

.Linechart {
  margin-bottom: 40px;
}

.Linechart {
  margin-bottom: 40px;
}

.usertable {
  margin-top: 30px;
}

.usertable table {
  position: relative;
}

.usertable table thead th {
  background: linear-gradient(
      180deg,
      rgb(33 160 218 / 10%) -28.9%,
      rgb(157 211 130 / 10%) 100%
    ),
    #ffffff;
  padding: 15px 30px !important;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.438rem;
  letter-spacing: 0.03em;
  color: var(--base-primary);
  border: none;
}

.usertable table tbody tr td {
  padding: 15px 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.438rem;
  color: #272727;
  border: none;
}

.usertable table thead th:first-child {
  border-radius: 8.78571px 0 0 8.78571px;
}

.usertable table thead th:last-child {
  border-radius: 0 8.78571px 8.78571px 0;
}

.usertable table tbody tr td .number-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.usertable table tbody tr td .number-bar p {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.438rem;
  color: #272727;
}

.usertable table tbody tr td .number-bar .user-dropdown ul.Dropdown-listing {
  top: 20px;
  background: var(--base-purewhite);
}

.usertable table tbody tr td .number-bar .user-dropdown svg {
  cursor: pointer;
}
.usertable table tbody tr td,
.usertable table tbody tr th {
  vertical-align: middle;
}
.usertable table img {
  width: 50px;
}
.Admin-modal .modal-footer {
  border: none;
  justify-content: center;
}

.Admin-modal .modal-footer button {
  margin: 0 10px;
}

.Admin-modal .modal-body p {
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--base-secondry);
  text-align: center;
  margin: 0;
}

.Admin-modal .modal-content {
  padding: 30px 0;
}

.Titlebar-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Titlebar-btns button,
.Titlebar-btns .sendbtn a {
  margin-right: 20px;
  padding: 14px 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: var(--base-purewhite);
  background: var(--base-primary) !important;
  border-radius: 5px;
}

.Titlebar-btns .searchbar {
  position: relative;
}

.Titlebar-btns .searchbar input {
  padding: 17px 17px 17px 50px;
  border-radius: 8px;
  background: #fdfdfd;
  border: 1px solid #dcdcdc;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.188rem;
  color: #272727;
}

.Titlebar-btns .searchbar svg {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(0px, -50%);
  color: #b5b5c3;
  font-size: 1.5rem;
}

.Titlebar-btns .searchbar input::placeholder {
  color: #b5b5c3;
}

.action-bar .flagbtnbar li {
  margin: 0;
}

.action-bar .flagbtnbar li button {
  margin-left: 40px;
  padding: 13px 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-danger {
  border: 1px solid var(--base-purewhite) !important;
  background: transparent !important;
  color: var(--base-purewhite) !important;
}

.bg-danger:hover {
  background: var(--base-purewhite) !important;
  color: #000000 !important;
}

.Username-titlebar {
  margin-top: 20px;
}

.Username-titlebar h6 {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--base-secondry);
}

.Username-titlebar h6 svg {
  margin-right: 10px;
  color: var(--base-primary);
}

.verification-bar {
  background: var(--base-purewhite);
  box-shadow: 0px 4px 24px rgba(204, 204, 204, 0.15);
  border-radius: 20px;
  padding: 25px 30px;
  margin-bottom: 20px;
  position: relative;
}

.verification-bar .verification-media-upload {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
}

.verification-bar .verification-media-upload .mediabar {
  width: 298px;
  height: 292px;
  border: 1px solid var(--base-border);
  border-radius: 20px;
  margin-right: 25px;
  flex: 0 0 298px;
}

.verification-bar .verification-media-upload .verify-content .formbar {
  margin-bottom: 30px;
}

.verification-bar .verification-media-upload .verify-content .formbar label {
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.188rem;
  color: #929292;
  margin-bottom: 5px;
  display: block;
}
.tabswithbutotn {
  position: relative;
}
.usertable table img {
  width: 50px;
}

.flexbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 0 10px 0;
}

.flexbar ul.admintable-tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flexbar ul.admintable-tabs a.tabbtn {
  background: #ececec;
  border-radius: 5px;
  width: 190px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.188rem;
  text-align: center;
  color: #8b8b8b;
}

.flexbar ul.admintable-tabs a.tabbtn.active {
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  border-radius: 5px;
  color: #000000;
}

.addbtn {
  border-radius: 6px;
  border-style: solid;
  border: 3px solid;
  border-image-slice: 1 !important;
  border-width: 2px;
  border-image-source: linear-gradient(to left, #98d185, #40adc3) !important;
  width: 155px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.063rem;
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.user-dropdown .Dropdown-listing .deltebtn {
  color: #de210d !important;
}
.align-left {
  text-align: left !important;
}
.addpack {
  margin: 30px 0;
}
.crosicon {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1rem;
}
.tabswithbutotn .buttonoverall-size {
  position: absolute;
  top: 0;
  right: 0;
}

.tabswithbutotn .buttonoverall-size ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tabswithbutotn .buttonoverall-size ul li button {
  width: 40px;
  height: 40px;
  color: #000000;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #8acc8d;
  text-transform: capitalize;
  border-image-width: 1;
}
.tabswithbutotn .buttonoverall-size ul li button.active {
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
}
.tabswithbutotn .buttonoverall-size ul li {
  margin-left: 10px;
}
.verification-bar .verification-media-upload .verify-content .formbar h5 {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: var(--base-secondry);
}
.addawards-editorbar {
  margin-top: 30px;
}

.addawards-editorbar .editor-input select.form-select {
  width: 100%;
}

.verification-bar .formbarbtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.upload-media-verify {
  position: relative;
  overflow: hidden;
}

p.uploaded-note {
  margin: 5px 0 0 0;
  font-size: 0.938rem;
  line-height: 1rem;
}

p.uploaded-note b {
  font-weight: 700 !important;
  color: var(--base-primary);
}

/* .upload-media-verify img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  left: 0;
  top: 0;
} */
.upload-media-verify img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  left: 0;
  top: 0;
  display: none;
}
.verification-bar .verification-media-upload .verify-content {
  width: 100%;
}

.verification-bar .formbarbtn button {
  margin-left: 20px;
}

.notification-list li:first-child {
  flex: 0 0 32px;
}

[darkmode] .modal-content .modal-body::-webkit-scrollbar {
  width: 0px;
}

/********** DARKMODE CSS  **********/
[darkmode] .no-comment {
  color: var(--base-offwhite);
}

[darkmode] p.uploaded-note {
  background: #383838 !important;
}

[darkmode] body {
  background: var(--darkmode-darkblack);
}

[darkmode] .header-nav {
  background: var(--darkmode-lightgray);
}

[darkmode] .header-nav img.lightmode {
  display: none;
}

[darkmode] .sidebar-profile {
  background: var(--darkmode-lightblack);
  box-shadow: none;
}

[darkmode] .sidebar-profile {
  background: var(--darkmode-lightblack);
  box-shadow: none;
}

[darkmode] .edit-bar {
  background: #515151 !important;
  border-color: #515151 !important;
}

[darkmode] .search-input-form form.user-searchform input {
  background: var(--darkmode-offgray);
  border-color: #565656;
  outline: none !important;
}
#theme-switch .switch-icon svg {
  font-size: 20px;
  color: #FFAB08;
}
.action-bar ul li.active {
  font-weight: bold;
}

[darkmode] .search-input-form form.user-searchform input::placeholder {
  color: #919191;
}

[darkmode] input.form-control {
  background: #252525;
  border: 2px solid #565656;
  color: var(--base-purewhite) !important;
}

[darkmode] .edit-bar svg,
[darkmode] .user-detail-listing li p,
[darkmode] .header-nav .navbar-nav .nav-link,
[darkmode] .text-secondry,
[darkmode] .sidebar-profile .sidebar-menu li a.active,
[darkmode] .sidebar-profile .sidebar-menu li a:hover,
[darkmode] .hoverdropdown button:hover,
[darkmode] .thredsbar h4 {
  color: var(--base-purewhite) !important;
}

[darkmode] .user-detaiting-listing li {
  color: var(--base-text-lightgray);
}

[darkmode] .user-detail-listing li h6 {
  color: var(--base-text-lightgray);
}

[darkmode] .Whatsmind-bar {
  background: var(--darkmode-lightblack);
  box-shadow: none;
}

[darkmode] .Whatsmind-bar form textarea {
  background: #252525;
  border: 2px solid #565656;
}

[darkmode] .digital-tabs li .nav-link,
[darkmode] ul.profiletabsbar li a {
  border: 1px solid var(--darkmode-offgray);
  background: var(--darkmode-offgray);
  color: var(--base-text-lightgray);
}

[darkmode] .digital-tabs li .nav-link.active,
[darkmode] ul.profiletabsbar li a.active {
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff !important;
  border-color: none !important;
}

[darkmode] .pagetitle {
  color: var(--base-text-lightgray);
  border-color: var(--base-text-lightgray);
}

[darkmode] .Whatsmind-bar form textarea {
  background: #252525;
  border: 2px solid #565656;
}

[darkmode] p.uploaded-note b {
  color: var(--base-purewhite);
}

[darkmode] p.uploaded-note {
  color: var(--base-text-lightgray);
}

[darkmode] .Authbar-innerbox.E-Otp h4 {
  color: var(--base-purewhite);
}

[darkmode] #theme-switch .switch-icon svg {
  font-size: 1.25rem;
  color: var(--base-purewhite);
}

[darkmode] .digital-feeds {
  background: var(--darkmode-lightblack) !important;
  box-shadow: none;
}

[darkmode] .text-primary {
  color: var(--base-purewhite) !important;
}

[darkmode] .action-bar ul li:hover,
[darkmode] .action-bar ul li.active {
  color: var(--base-purewhite);
}

[darkmode] .bg-darkgray {
  background: var(--darkmode-offgray);
}

[darkmode] .digital-feeds .Description-bar p {
  color: var(--darkmode-offwhite);
}

[darkmode] .digital-feeds .Description-bar p {
  color: var(--darkmode-offwhite);
}

[darkmode] .user-edit textarea.form-control,
[darkmode] .user-edit input {
  background: var(--darkmode-offgray);
  border-color: var(--darkmode-offgray);
  color: var(--darkmode-offwhite);
}

[darkmode] .outline-primary,
[darkmode] .btn-outline-primary {
  background: var(--darkmode-darkblack) !important;
  color: var(--base-purewhite) !important;
  border: 1px solid #565656 !important;
}

[darkmode] .outline-primary:hover,
[darkmode] .btn-outline-primary:hover {
  background: var(--base-primary) !important;
  color: var(--base-purewhite) !important;
  border: 1px solid var(--base-primary) !important;
}

[darkmode] .accordion .accordion-collapse {
  background: var(--darkmode-lightblack);
  box-shadow: none;
}

[darkmode] .Auth-bar .Authbar-innerbox form .authinputbar .form-control {
  background: var(--darkmode-offgray);
  border: 2px solid var(--darkmode-offgray);
  border-radius: 8px;
  height: auto;
  padding: 14px 16px;
  color: var(--base-purewhite);
}

[darkmode]
  .Auth-bar
  .Authbar-innerbox
  form
  .authinputbar
  .form-control::placeholder {
  color: #898989;
}

[darkmode] .Description-bar p {
  color: var(--darkmode-offwhite);
}

[darkmode]
  .actionleftbar
  .accordion-header
  button.accordion-button:not(.collapsed),
[darkmode] .Actions-modal .modal-header .modal-title.h4,
[darkmode] .Actions-modal .modal-header button.btn-close,
[darkmode] .Actions-modal .modal-body .aggree-li li .user-del h4,
[darkmode] .dashboard-title-bar h4 {
  color: var(--base-purewhite);
}

.Actions-modal .modal-body::-webkit-scrollbar {
  display: none;
}

.retweetmodal .digital-feeds {
  background: #ffffff;
  border: 1px solid #d3d3d3;
}

.retweetmodal .digital-feeds .Description-bar {
  border: none;
  padding: 0px 0 15px 0;
}

.retweetmodal .digital-feeds .user-detail-bar {
  margin-top: 0;
}

[darkmode] .reply-post form .replyinput .form-control {
  background: var(--darkmode-offgray);
  border: 1px solid #d3d3d3;
  color: var(--base-placeholder);
}
.user-detail-bar .detailleft .follow-bar .followbtn {
  margin-bottom: 7px;
}
[darkmode] .reply-post form .btn {
  color: var(--base-purewhite);
}

[darkmode] .Actions-modal .modal-content {
  background: var(--darkmode-lightblack);
}

[darkmode] .Auth-bar .Authbar-innerbox {
  background: var(--darkmode-lightblack);
  border: 0.5px solid var(--darkmode-lightblack);
  box-shadow: none;
}

[darkmode] .Auth-bar .Authbar-innerbox h4,
[darkmode] .Auth-bar .Authbar-innerbox p {
  color: var(--base-purewhite);
}

[darkmode] .Notfy-block {
  background: var(--darkmode-lightblack);
  box-shadow: none;
}

[darkmode] .Notfy-block .notification-title h4,
[darkmode] .notification-title ul li .btn-nill {
  color: var(--base-purewhite);
}

[darkmode] .notification-content.unreadmark p.notify {
  color: var(--base-purewhite);
  font-weight: 600;
}

[darkmode] .notification-content .user-active-timer li.text-green {
  color: var(--base-purewhite);
}

[darkmode] .user-dropdown .Dropdown-listing {
  background: var(--darkmode-gray2xl) !important;
  border-color: #717171 !important;
}

[darkmode] .user-dropdown .Dropdown-listing h4.text-silver {
  color: var(--base-purewhite);
}

[darkmode] .user-dropdown .Dropdown-listing ul li,
[darkmode] .user-dropdown .Dropdown-listing ul li a {
  color: var(--base-purewhite) !important;
}

[darkmode] .user-dropdown .Dropdown-listing h4,
[darkmode] .user-dropdown .Dropdown-listing h6,
[darkmode] .user-dropdown .Dropdown-listing p {
  color: var(--base-purewhite) !important;
}

[darkmode] .notification-dropdown .notification-foot button {
  color: var(--base-purewhite);
}

[darkmode] .share-media li .sharebtn {
  background: var(--base-purewhite);
}

[darkmode] .share-media li p {
  color: var(--base-purewhite);
}

[darkmode] .awards-modal .modal-header button.btn-add {
  background: var(--darkmode-gray2xl);
  border: 1px solid var(--base-purewhite);
  color: var(--base-text-lightgray);
}

[darkmode] .Actions-modal .Awrds-li h4 {
  color: var(--base-text-lightgray);
}

[darkmode] .notification-dropdown .notification-foot a {
  color: var(--base-purewhite);
}

[darkmode] .editor-input .form-label {
  color: var(--base-purewhite);
}

[darkmode] .form-control,
[darkmode] input.form-control,
[darkmode] .form-select,
[darkmode] .form-control,
[darkmode] .form-control {
  background: #272728 !important;
  border: 2px solid #565656;
  color: var(--base-purewhite);
}

[darkmode] .Uploaded-user .Imagebar {
  border: 10px solid #565656;
}

[darkmode] .save-form-btn .outline-primary {
  background: var(--base-purewhite) !important;
  color: var(--darkmode-darkblack) !important;
}

[darkmode] .upload-media-verify {
  background: rgba(255, 255, 255, 0.08);
}

[darkmode] .passwrd-verify .resetbtn {
  color: var(--base-purewhite) !important;
}

[darkmode] .dashboard-boxes .dash-boxes-in {
  background: var(--darkmode-gray2xl);
  border: 1px solid #717171;
}

[darkmode] .dashboard-boxes .dash-boxes-in ul.userstatus li .iconbar {
  background: #4e4e4e;
}

[darkmode] .dashboard-boxes .dash-boxes-in ul.weekly-status li h4,
[darkmode] .dashboard-boxes .dash-boxes-in ul.weekly-status h5,
[darkmode] .dashboard-boxes .dash-boxes-in ul.userstatus li svg {
  color: var(--base-purewhite);
}

[darkmode] .dashboard-title-bar select.form-select {
  background-color: #2e2d2d;
  border: 1px solid var(--base-purewhite);
}

[darkmode] .dashboard-title-bar select.form-select {
  background-color: #2e2d2d;
  border: 1px solid var(--base-purewhite);
}

[darkmode] .usertable table thead th {
  background: var(--darkmode-lightgray);
  color: var(--base-purewhite);
}

[darkmode] .usertable table tbody tr td .number-bar p,
[darkmode] .user-dropdown .Dropdown-listing li,
[darkmode] .user-dropdown .Dropdown-listing li a {
  color: var(--base-purewhite);
}

[darkmode] .usertable table tbody tr td,
[darkmode] .modal-content p,
[darkmode] .Username-titlebar h6 {
  color: var(--base-purewhite);
}

[darkmode] .Titlebar-btns .searchbar input {
  background: var(--darkmode-offgray);
  color: var(--base-purewhite);
}

[darkmode] .modal-content {
  background: var(--darkmode-lightblack);
}

[darkmode] .modal-content {
  background: var(--darkmode-lightblack);
}

[darkmode] .verification-bar {
  background: var(--darkmode-lightblack);
  box-shadow: none;
}

[darkmode] .verification-bar .verification-media-upload h5,
[darkmode] .verification-bar .verification-media-upload label {
  color: var(--base-purewhite) !important;
}

[darkmode] .account-verify label.form-label,
[darkmode] .Auth-bar .Authbar-innerbox form .authcheckbox .btn-simple a,
[darkmode] .Auth-bar .Authbar-innerbox form .Noted-bar h6,
[darkmode] .Auth-bar .Authbar-innerbox form .Noted-bar h6 a,
[darkmode] .Auth-bar .Authbar-innerbox form .terms-condition a,
[darkmode]
  .Auth-bar
  .Authbar-innerbox
  form
  .authcheckbox
  .form-check
  label.form-check-label {
  color: #ffffff;
}
[darkmode] .Userdetail-editorbar p.word-note {
  color: #ffffff;
}
/* .arrowshape {
    position: absolute;
    right: 0;
    top: 0;
    border-top: none;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: 15px solid #717171;
    width: 10px;
    top: -31px;
}

.arrowbar {
    position: relative;
} */
[darkmode] #theme-switch .switch-track {
  background-color: #656565;
}

[darkmode] .hoverdropdown .dropdown-menu {
  background: var(--darkmode-offgray);
  border: 1px solid #dddde3;
}

[darkmode] .hoverdropdown .dropdown-menu a.dropdown-item:hover {
  color: var(--base-purewhite);
  background: #000000 !important;
}
[darkmode] .hoverdropdown .dropdown-menu a.dropdown-item {
  color: var(--base-purewhite);
}
[darkmode] .report-tabs li {
  background: var(--darkmode-gray2xl);
  border: 2px solid var(--base-purewhite);
}

[darkmode] .Editreportmodal form label.form-label {
  color: var(--base-purewhite);
}

[darkmode] #theme-switch .switch-thumb {
  background-color: var(--darkmode-darkblack);
  border-color: var(--darkmode-darkblack);
}

ul.sidebar-menu {
  padding-bottom: 20px;
}

.header-nav .navbar-brand .darkmode {
  display: none;
}

[darkmode] .header-nav .navbar-brand .darkmode {
  display: block;
}

[darkmode] .Username-titlebar h6 svg {
  color: var(--base-purewhite);
}

[darkmode] .aboutline p {
  color: var(--darkmode-offwhite);
}

[darkmode] .aboutline h4 {
  color: var(--base-purewhite);
}

/* .level1 {
  color: var(--base-level1) !important;
} */

[darkmode] .Whatsmind-bar form textarea {
  color: var(--base-purewhite);
}

[darkmode] .Whatsmind-bar form textarea::placeholder {
  color: #919191;
}

.notify-add .btndot::after {
  display: none;
  content: "";
  width: 9px;
  height: 9px;
  background: #de210d;
  border-radius: 50%;
  position: absolute;
  left: -3px;
  top: 47%;
  transform: translate(0px, -50%);
}

.header-nav .navbar-nav a.nav-link {
  position: relative;
}

[darkmode] .Auth-bar .Authbar-innerbox form .authinputbar label.form-label {
  color: var(--base-purewhite);
}
[darkmode] .Auth-bar .Authbar-innerbox form .authinputbar label.form-label svg {
  fill: white;
}
[darkmode] .Auth-bar .Authbar-innerbox form .authinputbar .form-control[disabled] {
  background: #3a3a3a !important;
  cursor: no-drop;
}
[darkmode] button.btn-close {
  filter: invert(1);
}

[darkmode] input:-webkit-autofill,
[darkmode] input:-webkit-autofill:hover,
[darkmode] input:-webkit-autofill:focus,
[darkmode] textarea:-webkit-autofill,
[darkmode] textarea:-webkit-autofill:hover,
[darkmode] textarea:-webkit-autofill:focus,
[darkmode] select:-webkit-autofill,
[darkmode] select:-webkit-autofill:hover,
[darkmode] select:-webkit-autofill:focus {
  border: 1px solid var(--base-purewhite);
  -webkit-text-fill-color: var(--base-inputautofill);
  -webkit-box-shadow: 0 0 0px 1000px var(--base-purewhite) inset;
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}
.scroll_slim::-webkit-scrollbar{
  width: 3px;
  border-radius: 80px;
}
.scroll_slim::-webkit-scrollbar-track {
  background: #e5fed15a;
  border-radius: 10px;
  width: 2px;
}
.scroll_slim::-webkit-scrollbar-thumb {
  background: var(--base-green);
  border-radius: 10px;
}
.accordionitem.scroll_slim-slim::-webkit-scrollbar{
  width: 3px;
  border-radius: 80px;
}
.accordionitem.scroll_slim-slim::-webkit-scrollbar-track {
  background: #e5fed15a;
  border-radius: 10px;
  width: 2px;
}
.accordionitem.scroll_slim-slim::-webkit-scrollbar-thumb {
  background: var(--base-green);
  border-radius: 10px;
}
[darkmode] .sidebar-profile .sidebar-menu li a.active,
[darkmode] .sidebar-profile .sidebar-menu li a:hover {
  background-color: #303031;
  /* background-image: url("../../../public/images/darksidebar-menushape.png"); */
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}
[darkmode] .modal-content .modal-body .shareurl-bar {
    background: #272728;
}
.user-active-timer ul li {
  font-size: 0.875rem;
  line-height: 1.063rem;
}

[darkmode] .Editreportmodal form textarea {
  background: #252525;
  border: 2px solid #565656;
}

[darkmode] .modal-content .modal-title.h4 {
  color: var(--base-purewhite);
}

[darkmode] .modal-content .modal-body {
  color: var(--base-purewhite);
}

.user-active-timer ul li svg {
  margin-right: 5px;
  font-size: 1.25rem;
}

.Linechart,
.Countrychart {
  display: none;
}

@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap");

.notfound {
  height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.notfound-content {
  text-align: center;
}

.notfound-content h2 {
  font-size: 220px;
  line-height: 1;
  font-weight: bolder;
  font-family: "Alfa Slab One", cursive;
  margin: 0;
  background: -webkit-linear-gradient(#000000, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 6px 0px 0px #0a2256;
}

.notfound-content h4 {
  font-size: 36px;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 30px;
}

.notfound-content p {
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 30px;
}

.Noheader nav.header-nav.navbar {
  display: none;
}

.E-Otp .authinputbar .form-control {
  background: #f8f8f8;
  border-radius: 8px;
  padding: 20px 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.625rem;
  color: var(--base-default-text);
  border: none;
}

.E-Otp form {
  text-align: right;
}

.E-Otp form input.btn.btn-primary {
  margin-top: 20px;
}

.imagebarupload {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.imagebarupload p.succesful {
  margin: 0px 0 0 10px;
  font-size: 1rem;
  line-height: 1.125rem;
}
.shareurl-bar {
  background: #ffffff;
  border: 1.24px solid #dddddd;
  border-radius: 4.96px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
  padding: 20px;
}
.shareurl-bar p {
  display: block;
  margin-bottom: 0;
  padding-right: 60px;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.063rem;
  color: #aaaaaa;
  word-break: break-all;
}
.shareurl-bar svg {
  font-size: 1.25rem;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(0px, -50%);
}
.mainheader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Mob-Footerbar {
  padding: 5px 0px;
  position: fixed;
  bottom: 0;
  filter: drop-shadow(0px 8px 9px rgb(0,0,0,0.4));
  width: 100%;
  left: 0;
  background: var(--base-purewhite);
}
[darkmode] .Mob-Footerbar,
[darkmode] .Mob-Footerbar .mob-foot-menu li.Addpost a {
  background: #383838;
}
.Mob-Footerbar .mob-foot-menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Mob-Footerbar .mob-foot-menu li {
  flex: 0 0 20%;
  max-width: 20%;
  text-align: center;
}
/* 
.Mob-Footerbar .mob-foot-menu li.Addpost a {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0 auto;
  border-radius: 50%;
  background: var(--base-purewhite);
  border: 0.5px solid var(--base-border);
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.06);
  position: relative;
  font-size: 52px;
  color: #b9b9b9;
  transition: 0.5s ease;
} */
.Mob-Footerbar .mob-foot-menu li a svg {
  color: #7e7e7e;
  font-size: 60px;
}
[darkmode] .Mob-Footerbar .mob-foot-menu li a svg{
  color: #ffffff;
}
.Mob-Footerbar .mob-foot-menu li a {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Mob-Footerbar .mob-foot-menu li.user-dropdown {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--base-green);
}
.Mob-Footerbar .mob-foot-menu li a{
  color: var(--base-green);
}

.Mob-Footerbar .mob-foot-menu li.user-dropdown .Dropdown-listing ul li a {
  flex-direction: row;
}
.Mob-Footerbar .mob-foot-menu li.user-dropdown img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.Mob-Footerbar .mob-foot-menu li.user-dropdown .Dropdown-listing ul li a svg {
  font-size: 1.125rem;
  color: #171717;
}
[darkmode] .Mob-Footerbar .mob-foot-menu li.user-dropdown .Dropdown-listing ul li a svg{
  color: #ffffff;
}
.Mob-Footerbar .mob-foot-menu li a img {
  width: 100%;
  height: auto;
}

.Mob-Footerbar .mob-foot-menu li.Addpost a:hover svg {
  transform: rotate(360deg);
}

.Mob-Footerbar {
  display: none;
}

.userleftside {
  display: flex;
  align-items: flex-start;
}

.flagbtnbar button.bg-danger {
  border: 1px solid var(--base-primary) !important;
  color: var(--base-primary) !important;
}

[darkmode] .flagbtnbar button.bg-danger {
  border: 1px solid var(--base-purewhite) !important;
  color: var(--base-purewhite) !important;
}

.pagetitle {
  font-weight: 600;
  font-size: 1.875rem;
  line-height: 2.375rem;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  margin-bottom: 20px;
  padding-bottom: 5px;
  display: inline-block;
  padding-right: 20px;
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  border-style: solid;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  border: 3px solid;
  border-image-slice: 1 !important;
  border-width: 2px;
  border-image-source: linear-gradient(to left, #98d185, #40adc3) !important;
}
[darkmode] .tabswithbutotn .buttonoverall-size ul li button {
  color: #ffffff;
}
[darkmode] .tabswithbutotn .buttonoverall-size ul li button.active {
  color: #000000;
}
[darkmode] .dashboard-boxes .dash-boxes-in ul.userstatus li h5 {
  color: var(--base-text-lightgray) !important;
}

[darkmode] .bg-primary {
  background-color: var(--darkmode-lightgray) !important;
  border-color: var(--darkmode-lightgray) !important;
}

/* .editor-same-line {
  display: flex;
  align-items: center;
  justify-content: flex-start;
} */
.editor-same-line {
  display: block;
  align-items: center;
  justify-content: flex-start;
  max-width: 570px;
}
/* .editor-same-line .upload-media-verify {
  flex: 0 0 49%;
  margin-right: 30px;
} */
.editor-same-line .upload-media-verify {
  margin-right: 0;
}
.editor-same-line p.uploaded-note {
  margin: 0;
  background: var(--base-offgray);
  padding: 20px;
}

.empty-bar {
  text-align: center;
}

.empty-bar img {
  max-width: 100%;
  margin: 0 auto 30px auto;
  height: 200px;
  object-fit: contain;
}

.empty-bar p {
  font-size: 1rem;
  margin: 0;
}
.particitionbar {
  border: 3px solid;
  border-image-slice: 1 !important;
  border-width: 2px;
  border-image-source: linear-gradient(to left, #98d185, #40adc3) !important;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
}
.addpackbox {
  padding: 0 50px;
}
.digital-feeds .digital-feeds.diffrentiate-bar {
  margin-left: 30px;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 1px solid #d3d3d3;
}

.digital-feeds .digital-feeds.diffrentiate-bar .Description-bar {
  border: none;
}

.small .digital-feeds .Description-bar p {
  font-size: 1rem;
}
.tabswithbutotn .buttonoverall-size ul li .sm {
  font-size: 1rem;
}
.tabswithbutotn .buttonoverall-size ul li .md {
  font-size: 19px;
}
.tabswithbutotn .buttonoverall-size ul li .lg {
  font-size: 1.375rem;
}
.medium .digital-feeds .Description-bar p {
  font-size: 19px;
}
.large .digital-feeds .Description-bar p {
  font-size: 1.375rem;
}
.sidebar-profile .feature-image {
  position: relative;
  height: 120px;
  object-fit: contain;
  overflow: hidden;
}
.package-listtb {
  max-width: 100%;
}

.package-listtb tr td,
.package-listtb tr th {
  border: none;
  vertical-align: middle;
}

.package-listtb tr th {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #aaaaaa;
}
.package-listtb tr td img {
  border: 0.4px solid #dddddd;
  border-radius: 119.628px;
  margin-right: 12px;
  width: 51px;
  height: 51px;
}
.package-listtb tr td {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #171717;
}

.package-listtb tr td:first-child {
  color: #aaaaaa;
}
.addpack.align-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.selectawards-scrollbar {
  max-height: 340px;
  overflow: scroll;
}

.Editreportmodal .Awrds-li img,
.Actions-modal .Awrds-li img {
  margin-bottom: 20px;
  width: 100px;
  height: 100px;
  object-fit: contain;
}
.selectawards-scrollbar .Awrds-li svg.showicon {
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(to left, #98d185, #40adc3) !important;
  padding: 5px;
  font-size: 1rem;
  color: #ffffff;
  width: 25px;
  height: 25px;
  opacity: 0;
  display: none;
}
.selectawards-scrollbar .Awrds-li .active svg.showicon {
  opacity: 1;
  display: block;
}
.whatsmind-bar-btn {
  padding: 0 20px 20px 20px;
}
.auto-resizable-textarea{
  resize: none; /* Prevent manual resizing */
  overflow-y: hidden; /* Hide vertical scrollbar */
  min-height: var(--rows, 1.5rem);
}
.reply-post textarea.form-control {
  min-height: 80px;
  padding-right: 70px;
  overflow: hidden;
}
.upload-media-verify button input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.verification-bar .verification-media-upload .mediabar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.thredsbar .thredsbar .thredsbar {
  max-height: 100% !important;
}
.selectawards-scrollbar .Awrds-li .awards {
  border: 1px solid transparent;
}
.selectawards-scrollbar .Awrds-li .active {
  position: relative;
  border-radius: 10px;
  border: 1px solid;
  border-image-slice: 1 !important;
  border-width: 1px;
  border-image-source: linear-gradient(to left, #98d185, #40adc3) !important;
}
.Editreportmodal .modal-body {
  height: auto;
  overflow: hidden;
}
.awardscountbar button.btn {
  margin-top: 20px;
}
.accordionitem .thredsbar {
  max-height: 100% !important;
}
.thredsbar .accordionitem {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 100px;
  margin-bottom: 120px;
}

.thredsbar .accordionitem .accordionitem {
  max-height: initial;
  overflow: initial;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.notification-dropdown .notification-content .notification-text .awards-note{
  display: none;
}
.maineditor .whtsmind-btn-space {
  padding: 14px;
}
.refer_input .input-group-text {
  width: 70px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reply-post.reply-fix-bottom {
  position: absolute;
  bottom: 20px;
  width: 94%;
  left: 50%;
  max-width: 100%;
  transform: translate(-50%, 0px);
  padding: 0;
}
.reply-post {
  width: 100%;
  padding: 0;
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #d1d1d1;
  box-shadow: 0px 0px 5px #8080803d;
}
.reply-post form {
  display: block;
}
.reply-post form .ql-container {min-height: auto;}
.reply-post form .ql-container .ql-editor {
  min-height: 50px !important;
    padding-right: 140px;
}
.digital-feeds .Description-bar div {
  white-space: inherit !important;
}
.digital-feeds .Description-bar div span {
  margin: 0 3px 0 0 !important;
  height: auto;
}
.buymore-modal .Awrds-li h3 span {
  font-size: 0.875rem;
}

ul.actionrytbar {
  flex: 0 0 20%;
  justify-content: flex-end;
}
.actionrytbar li.viewpost-btn {
  display: none;
}
.ql-snow .ql-picker {
  height: auto !important; 
}
.editor .ql-toolbar.ql-snow span.ql-formats:last-child {
  display: none;
}
.ql-snow .ql-picker.ql-header {
  width: auto !important;
}
.ql-snow .ql-picker.ql-header span.ql-picker-label {
  padding-left: 0 !important;
}
.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  width: 16px !important;
  position: relative !important;
  top: inherit !important;
  margin: 0 !important;
}

.profile-feature-image {
  display: none;
}

.user-detail-bar {
  align-items: flex-end;
}
/* .user-detail {
  margin-top: 24px;
} */
.navbar-nav svg {
  font-size: 30px;
  color: #AAAAAA ;
}
[darkmode] .navbar-nav svg {
  color: #AAAAAA;
}
.user_icon span {
  color: #ffffff;
  margin-top: 6px;
  position: absolute;
  top: -17px;
  right: -7px;
  z-index: 3;
  font-size: 14px;
  background: var(--base-primary);
  width: 20px;
  height: 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user_icon{
  display: flex;
  column-gap: 8px;
  align-items: center;
  position: relative;
}
.sidebar-menu.col-lg-3{
  margin-top: 65px;
}
.user_info_sidebar p{
font-size: 18px;
font-weight: 700;
line-height: 1;
margin-bottom: 10px;
text-transform: capitalize;
}
.user_info_sidebar a{
  border: 1px solid var(--base-green);
  border-radius: 5px;
  padding: 6px 10px;
  text-decoration: none;
  color: var(--base-green);
  font-size: 12px;
  background: var(--base-purewhite);
}

.editor{
  margin-bottom: 0px;
  position:relative;
}

.highlighted{
  /* font-weight: bold; */
background: linear-gradient(to right, rgba(157, 211, 131, 1), rgba(62, 172, 198, 1));  
-webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
}


#create_post_user_dropdown div, #create_post_hashtag_dropdown div , #edit_post_user_dropdown div, #tag-edit-dropdown div , #amplify_post_user_dropdown div , #apmlify_post_hashtag_dropdown div , #create_comment_user_dropdown div , #create_comment_hashtag_dropdown div{
  cursor: pointer; 
  padding: 5px;
}

/* dropdown  */
#create_post_user_dropdown, #edit_post_user_dropdown , #amplify_post_user_dropdown , #create_comment_user_dropdown{
  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: var(--base-lightgray);
  border: 1px solid var(--base-border);
  border-radius: 0 20px 20px 20px;
  color: #00c350;
  padding: 10px;
  width: 200px;
  z-index: 10; /* Ensure the dropdown appears above other elements */
}

#create_post_hashtag_dropdown, #edit_post_hashtag_dropdown , #apmlify_post_hashtag_dropdown , #create_comment_hashtag_dropdown{
  position: absolute;
  left: 20px;
  display: none;
  background-color: var(--base-lightgray);
  border: 1px solid var(--base-border);
  border-radius: 0 20px 20px 20px;
  color: #00c350;
  max-height: 300px;
  overflow-y: scroll;
  padding: 10px;
  width: 200px;
  z-index: 1; /* Ensure the dropdown appears above other elements */
}

 .editor .ql-container {
  border: none;
  min-height: 160px;
}
 .editor .ql-editor{
  min-height: 158px;
  padding-right: 15px;
}
body .ql-container.ql-snow{
  border: none;
}
.editpost-editor .editor .ql-toolbar.ql-snow {
  background: transparent;
  border: 1px solid var(--base-border) !important;
  border-bottom: 0 !important;
}
.editpost-editor .editor .ql-container.ql-snow {
  border: 1px solid #dfdfdf !important;
  border-radius: 20px 20px 20px 20px;
}
.editpost-editor .editor .quill.quillactive .ql-container.ql-snow {
  border-radius: 0 0 20px 20px;
}
.editpost-editor .editor .ql-container.ql-snow
.edit-post-buttons {
  display: flex;
  column-gap: 20px;
  align-items: center;
  justify-content: flex-end;
}

.editor .ql-toolbar.ql-snow {
  border: none !important;
  background: var(--base-purewhite);
  border-bottom: 1px solid rgb(223 223 223 / 52%) !important;
  border-radius: 20px 20px 0 0;
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
[darkmode]  .editor .ql-toolbar.ql-snow {
  border: none;
  background: var(--darkmode-lightgray);  
  border-bottom: 1px solid rgb(0 0 0 / 30%) !important;
}
[darkmode]  .editor .ql-container{
  border: none;
}
.user_icon{
  cursor: pointer;
}
[darkmode]  .editor .ql-toolbar.ql-snow span{
color: white;
stroke: white;
}
[darkmode] .Whatsmind-bar .editor .ql-snow .ql-stroke{
stroke: white;
}
.ql-snow.ql-toolbar button:hover, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar button:focus, .ql-snow .ql-toolbar button:focus, .ql-snow.ql-toolbar button.ql-active, .ql-snow .ql-toolbar button.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item.ql-selected{
  color:var(--base-primary) !important;
}
.ql-snow .ql-editor h6 {
  font-size: 1.375rem !important;
}
.ql-snow .ql-editor h5 {
  font-size: 1.5rem !important;
}
.ql-snow .ql-editor h1, .ql-snow .ql-editor h2, .ql-snow .ql-editor h3, .ql-snow .ql-editor h4, .ql-snow .ql-editor h5, .ql-snow .ql-editor h6 {
  font-weight: normal !important;
}
.ql-snow .ql-editor h4 {
  font-size: 1.625rem !important;
}
/* Editor css  */
.ql-toolbar.ql-snow {width: 100%;transition: .3s ease;margin-top: -53px;}
.quill {
  overflow: hidden;
}
.quill.quillactive {
  overflow: visible !important;
}
.quill.quillactive .ql-toolbar.ql-snow {margin-top: 0;}
.ql-snow.ql-toolbar button:hover .ql-fill, .ql-snow .ql-toolbar button:hover .ql-fill, .ql-snow.ql-toolbar button:focus .ql-fill, .ql-snow .ql-toolbar button:focus .ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill, .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill, .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--base-primary) !important;
}
[darkmode] .ql-snow.ql-toolbar button:hover .ql-fill, 
[darkmode] .ql-snow .ql-toolbar button:hover .ql-fill,
 [darkmode] .ql-snow.ql-toolbar button:focus .ql-fill,
 [darkmode] .ql-snow .ql-toolbar button:focus .ql-fill,
 [darkmode] .ql-snow.ql-toolbar button.ql-active .ql-fill,
 [darkmode] .ql-snow .ql-toolbar button.ql-active .ql-fill,
 [darkmode] .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
 [darkmode] .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
 [darkmode] .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
 [darkmode] .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
 [darkmode] .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
 [darkmode] .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
 [darkmode] .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
 [darkmode] .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
 [darkmode] .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
 [darkmode] .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
 [darkmode] .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
 [darkmode] .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
 [darkmode] .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
 [darkmode] .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
 [darkmode] .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
 [darkmode] .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
 [darkmode] .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
 [darkmode] .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
 [darkmode] .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
 [darkmode] .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
 [darkmode] .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
 [darkmode] .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--base-primary) !important;
  stroke: var(--base-primary) !important;
  stroke-width: 0px;
}
.ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter{
  stroke: var(--base-primary) !important;
}
.Whatsmind-bar .editor .ql-snow .ql-picker{
  height: auto;
}
.Description-bar h1, .Description-bar h2, .Description-bar h3, .Description-bar h4, .Description-bar h5, .Description-bar h6, .Description-bar p{
  color: var(--base-default-text);
  word-wrap: break-word;
}
.retweetmodal.modal-body {
  padding: 10px 0px;
}
.retweetmodal .digital-feeds.feed-type {
  padding: 0 15px;
}
[darkmode] .Description-bar h1, [darkmode] .Description-bar h2, [darkmode] .Description-bar h3, [darkmode] .Description-bar h4, [darkmode]  .Description-bar h5, [darkmode] .Description-bar h6, [darkmode] .Description-bar p{
  color: var(--darkmode-offwhite);
  word-wrap: break-word;
}
[darkmode] .Whatsmind-bar .ql-editor p,
[darkmode]  .ql-editor ol li,
[darkmode] .ql-editor ul li,
[darkmode] .ql-editor pre,
[darkmode] .ql-editor blockquote,
[darkmode] .ql-editor h1,
[darkmode] .ql-editor h2,
[darkmode] .ql-editor h3,
[darkmode] .ql-editor h4,
[darkmode] .ql-editor h5,
[darkmode] .ql-editor h6{
  display: block !important;
}
.digital-feeds .Description-bar {
  padding: 0px 0 20px 0;
}
[darkmode] .Whatsmind-bar .ql-editor p,[darkmode] .ql-editor ol,[darkmode] .ql-editor ul,[darkmode] .ql-editor pre,[darkmode] .ql-editor blockquote,[darkmode] .ql-editor h1,[darkmode] .ql-editor h2,[darkmode] .ql-editor h3,[darkmode] .ql-editor h4,[darkmode] .ql-editor h5,[darkmode] .ql-editor h6{
  color: var(--darkmode-offwhite);
}
.ql-snow .ql-picker-options{
  background-color: var(--base-lightgray) !important;
}
[darkmode] .ql-snow .ql-picker-options{
  background-color: var(--darkmode-lightgray)!important;
}

ul.user-detail-listing{
  justify-content: space-between !important;
}
.user-edit-cover {
  min-width: 321px;
}
.digital-feeds .Description-bar .ql-align-center {
    text-align: center;
}
.digital-feeds .Description-bar .ql-align-right {
    text-align: right;
}
.ql-editor ol > li, .ql-editor ul > li{
  font-size: 16px;
}
.ql-snow .ql-editor h1, .ql-snow .ql-editor h2, .ql-snow .ql-editor h3, .ql-snow .ql-editor h4, .ql-snow .ql-editor h5, .ql-snow .ql-editor h6, .ql-snow .ql-editor li {
    color: #545456;
    word-break: break-word;
}
[darkmode] .ql-snow .ql-editor h1,[darkmode]  .ql-snow .ql-editor h2,[darkmode]  .ql-snow .ql-editor h3,[darkmode]  .ql-snow .ql-editor h4,[darkmode]  .ql-snow .ql-editor h5,[darkmode]  .ql-snow .ql-editor h6,[darkmode]  .ql-snow .ql-editor p, [darkmode] .ql-snow .ql-editor li {
    color: #ffffff;
    word-break: break-word;
}
[darkmode] .user_info_sidebar a{
  color: var(--base-green);
  background-color: transparent;
}
[darkmode] .user_info_sidebar p{
  color: var(--base-offgray);
}
.info_circle {
  background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  width: 45px;
  height: 45px;
  color: white;
}

.user_profile {
  flex-direction: row !important;
  width: 100%;
  justify-content: space-between !important;
  align-items: flex-end !important;
}
.notification-content li svg:hover {
  color: red;
}
.edit-post-buttons {
  align-items: center;
  -webkit-column-gap: 20px;
  column-gap: 20px;
  display: flex;
  justify-content: flex-end;
}
.edit-post-buttons .cancel {
  align-items: center;
  -webkit-column-gap: 5px;
  column-gap: 5px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  padding: 11px 37px;
}
.digital-feeds .Description-bar .Description-bar {
  padding: 0;
}
.digital-feeds .Description-bar .Description-bar {
  padding: 0;
}
.thredsbar .threads-rows .thredsbar .reply-post {
  position: relative !important;
  top: inherit !important;
  bottom: inherit !important;
  left: inherit !important;
  transform: inherit !important;
}
.reply-post.reply-fix-bottom .ql-editor {
  max-height: 140px;
  overflow: auto;
}
[darkmode] .ql-editor.ql-blank::before {
  color: #ffffff;
}
[darkmode] .ql-stroke {
  stroke: #ffffff !important;
}
.Reportsli svg,.viewpostli svg {
  height: 28px;
}
#tooltip {
  z-index: 9999;
}
.editpostli .dropdown-toggle svg {
  margin: 0;
}
.Dropdown-listing .arrowbar .notification-content .user-detail-bar .detailleft {
    flex: 0 0 90%;
    max-width: 90%;
}

.Dropdown-listing .arrowbar .notification-content .user-detail-bar .detailleft .userleftside {
    width: 100%;
}

.Dropdown-listing .arrowbar .notification-content .user-detail-bar .detailleft .userleftside .user-detail {
    width: 100%;
}

.Dropdown-listing .arrowbar .notification-content .user-detail-bar .detailleft .userleftside .avatar-img {
    width: 40px;
    height: 40px;
}
.Dropdown-listing .arrowbar .notification-content .user-detail-bar .detailleft .userleftside .user-detail .rules-tag .levelbar {
    top: -8px !important;
}
/* Responsive css  */
@media (max-width: 1599px) {
  ul.trending-hashtag-list {
    padding: 15px;
}

ul.trending-hashtag-list li {
    margin-right: 10px;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

ul.trending-hashtag-list li.hashtaglist {
    font-size: 1rem;
    line-height: 1.75rem;
}
  .account-verify .verify-input button {
    font-size: 0.875rem;
  }
  .sidebar-profile .feature-image {
    position: relative;
    height: 100px;
    object-fit: contain;
    overflow: hidden;
  }
  .account-verify .verify-input input.form-control {
    margin-right: 20px;
  }

  .Userdetail-editorbar {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .editor-input {
    margin-bottom: 15px;
  }

  .editor-input textarea.form-control {
    height: 130px !important;
  }

  .reply-post form .btn {
    font-size: 1.4rem;
  }

  input.form-control {
    padding: 11px 20px !important;
  }

  .Auth-bar .Authbar-innerbox form .authinputbar .form-control {
    padding: 11px 20px;
  }

  .editor-input .form-label {
    font-size: 1rem;
    margin-bottom: 10px;
    line-height: 1.188rem;
  }

  .header-nav .navbar-nav .user-dropdown img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }

  .header-nav .navbar-nav a.nav-link {
    padding: 20px 0;
  }

  .header-nav .navbar-brand img {
    max-width: 100%;
  }

  .Whatsmind-bar {
    padding: 1px;
  }

  .reply-post form .replyinput .form-control {
    border-radius: 8px;
    padding: 15px 90px 15px 15px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.125rem;
  }

  .profile-feature-image {
    height: 245px;
    overflow: hidden;
  }

  .dashboard-title-bar {
    margin-bottom: 30px;
  }

  .aboutline {
    margin-bottom: 25px;
  }

  .aboutline h4 {
    margin-bottom: 5px;
  }

  .aboutline p {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .profile-user-detail .user-detail-bar .detailleft .avatar-img {
    width: 300px;
    height: 300px;
  }

  .profile-user-detail
    .user-detail-bar
    .detailleft
    .user-detail
    h4.text-secondry {
    font-size: 32px;
    line-height: 1.75rem;
  }

  .profile-user-detail
    .user-detail-bar
    .detailleft
    .user-detail
    .user-availbility
    h6.text-lightgray {
    font-size: 1.3rem;
  }

  .dashboard-title-bar h4 {
    font-size: 26px;
    line-height: 1;
  }

  .verification-bar .verification-media-upload {
    margin-top: 20px;
  }
  .text_level {
    margin-top: 10px;
    column-gap: 18px;
}

  .Titlebar-btns .searchbar input {
    padding: 14px 17px 14px 50px !important;
  }

  .usertable table thead th,
  .usertable table tbody tr td {
    font-size: 1rem;
  }

  .dashboard-title-bar select.form-select {
    font-size: 1.125rem;
  }

  .dashboard-boxes .dash-boxes-in ul.weekly-status li h4 {
    font-size: 1.5rem;
  }

  .dashboard-boxes .dash-boxes-in ul.userstatus li h5 {
    font-size: 1.125rem;
  }

  .dashboard-boxes .dash-boxes-in ul.userstatus li .iconbar {
    width: 44px;
    height: 44px;
    font-size: 1.25rem !important;
    margin-right: 10px;
  }

  .dashboard-boxes .dash-boxes-in ul.userstatus li svg {
    font-size: 1.5rem;
  }

  .dashboard-boxes .dash-boxes-in ul.weekly-status h5 {
    font-size: 1.125rem;
  }

  .dashboard-boxes .dash-boxes-in ul.weekly-status .valuebar p {
    font-size: 1.125rem;
  }



  .notification-title h4 {
    margin: 0;
  }

  .notification-content p.notify {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .Whatsmind-bar form textarea,
  .whatsmind-inputbar__input {
    height: 100px;
    font-size: 1rem;
    padding: 15px;
  }

  .user-detaiting-listing li {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }

  .User-detail {
    padding: 15px;
  }

  .User-detail .usermain-img img {
    width: 130px;
    height: 130px;
    object-fit: cover;
  }

  .User-detail .usermain-img {
    margin-top: -75px;
  }

  .digital-tabs {
    margin-top: 15px;
    position: relative;
    margin-bottom: 15px;
    border: none;
  }

  .digital-tabs li .nav-link {
    padding: 13px 40px;
  }

  .user-detail-listing {
    margin-top: 25px;
  }

  .Whatsmind-bar form textarea,
  .whatsmind-inputbar__input {
    height: 120px !important;
    font-size: 1rem;
    position: relative !important;
  }

  .Whatsmind-bar form textarea::placeholder,
  .whatsmind-inputbar__input::placeholder {
    font-size: 1rem;
  }

  .digital-feeds .user-active-timer ul li {
    font-size: 0.875rem;
  }

  .digital-feeds .user-active-timer ul li svg {
    font-size: 1.25rem;
  }

  .User-detail .username {
    margin: 15px 0 10px 0;
    font-size: 1rem;
  }

  .user-detaiting-listing {
    margin-bottom: 15px;
  }

  .sidebar-profile .sidebar-menu li a {
    padding: 10px 10px 10px 15px;
    font-size: 0.875rem;
  }

  .digital-feeds .Description-bar {
    padding-bottom: 15px;
  }

  .action-bar {
    padding: 15px 0 0 0;
  }

  .user-edit textarea.form-control,
  .user-edit input {
    padding: 15px;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 15px;
  }

  .sidebar-profile .sidebar-menu li a svg {
    font-size: 1.125rem;
    margin-right: 15px;
  }

  .container {
    max-width: 100%;
    padding: 0 50px;
  }

  .body-wrapper {
    padding: 15px 0;
  }

  .thredsbar {
    position: relative;
    padding: 20px 20px;
  }
  .accordionitem::-webkit-scrollbar {
    width: 20px;
  }

  /* Track */
  .accordionitem::-webkit-scrollbar-track {
    border-radius: 0px;
  }

  /* Handle */
  .accordionitem::-webkit-scrollbar-thumb {
    /* background: url("../../../public/images/scrollericon.png"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 0px;
    cursor: pointer;
  }

  /* Handle on hover */
  .accordionitem::-webkit-scrollbar-thumb:hover {
  }
  .action-bar ul li {
    margin-left: 0px;
    font-size: .75rem;
    font-weight: normal;
  }
  .action-bar ul li p {
    font-size: .75rem;
    font-weight: normal;
  }
  .hoverdropdown button,
  .action-bar ul li .accordion-button {
    line-height: 1.063rem;
    font-size: 0.875rem;
    font-weight: normal;
  }

  .Description-bar p {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sidebar-profile .feature-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .user-detail-listing li p {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }

  .search-input-form .user-searchform input {
    padding: 15px 45px 15px 15px;
    width: 100%;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.063rem;
    border-radius: 8px;
  }
}

@media (max-width: 1440px){
  .container {
    max-width: 100%;
    padding: 0 50px;
}
}

@media (max-width: 1299px) {
  .container {
    max-width: 100%;
    padding: 0 20px;
  }
  .header-nav .container {
    padding-left: 20px;
    padding-right: 20px;
}
.header-nav .container .navbar-nav {
  flex-direction: row;
}
.action-bar{
  flex-wrap: nowrap;
}
  .action-bar ul li svg {
    margin-right: 4px;
    transition: 0.3s ease;
}
  .action-bar .flagbtnbar li button {
    margin-left: 0 !important;
    margin-top: 20px;
    margin-right: 20px;
  }
}

@media (max-width: 1299px){
  .action-bar ul li .list-text {font-size: .75rem;}
  .action-bar ul li .list-text span.number {
      font-size: 1rem;
  }
  .action-bar ul li , .action-bar ul li p {
      font-size: .75rem;
  }
  .action-bar ul li span.number {
      font-size: 1rem;
  }
  .User-detail .usermain-img {
    margin-top: -75px;
    flex-direction: column;
    align-items: center;
  }
  .user-detail-listing {
    margin-top: 20px;
  }
  .user_info_sidebar {
    width: 100%;
    text-align: center;
  }
  .user_info_sidebar p{
    margin: 12px 0;
  }
}

@media (max-width: 1099px){
  
  .digital-feeds{
    padding: 15px 15px;
  }
  .action-bar ul li .list-text, .action-bar ul li .list-text span.number , .action-bar ul li, .action-bar ul li p{
    font-size: 10px;
}
.action-bar ul li svg {
  margin-right: 5px;
  transition: 0.3s ease;
}
.action-bar ul li{
  margin-bottom: 10px;
}
}
@media (max-width: 1024px){
  .profile-feature-image {
    -webkit-mask-image: none;
    mask-image: none;
}

}
@media (min-width: 991px) {

  /* .user-detail-listing li p {
    font-size: 1.1rem;
} */
  .action-bar ul li .list-text, .action-bar ul li .list-text span.number, .action-bar ul li, .action-bar ul li p {
    font-size: 0.875rem;
}
  .navbar-expand-lg .navbar-collapse {
    display: none !important;
    flex-basis: auto;
  }

  ul.web-none {
    display: none;
  }
}

@media (max-width: 991px) {
  .edit-post-buttons .cancel{
    padding: 9px 14px;
    column-gap: 5px;
  }
  .Mob-Footerbar .mob-foot-menu li a {
    color: var(--base-secondry) !important;
}
  .somewhere-pad-0.row {
    padding-left: 15px;
    padding-right: 15px;
}
  ul.sidebar-menu {
    overflow-x: hidden;
    overflow-y: scroll;
    height: auto;
}li.logout a {
  border: none !important;
}
.digital-feeds .Description-bar div span {
  margin: 0 3px 0 0 !important;
}
  .notification-come::after {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: red;
    position: absolute;
    top: 8px;
    right: 0px;
}
  .sidebar-menu.col-lg-3 {
    width: 100%;
  }
  .mainwrapper.col-lg-9 {
    width: 100%;
  }
  .navbar-collapse ul.sidebar-menu li a svg path {
    stroke: var(--base-text-lightgray);
  }
  .notification-title .user-detail-bar {
    display: flex;
  }
  .Mob-Footerbar .user-dropdown {
    position: relative;
    margin: 0;
  }
  
  .Mob-Footerbar .user-dropdown .Dropdown-listing {
    position: absolute;
    top: inherit;
    bottom: 60px;
  }
  .Mob-Footerbar .user-dropdown .Dropdown-listing ul li {
    width: 100%;
    margin: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .Mob-Footerbar .user-dropdown .Dropdown-listing ul li a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.75rem;
    cursor: pointer;
    text-decoration: none;
    width: 100%;
  }
  .header-nav .navbar-nav .user-dropdown {
    display: none;
  }
  .header-nav .navbar-nav .notify-add {
    display: block !important;
  }
  .thredsbar .thredsbar {
    padding: 5px;
  }
  .thredsbar .thredsbar .accordionitem {
    padding: 0;
  }
  .thredsbar .thredsbar .accordionitem .replyinput textarea {
    padding: 10px 80px 10px 10px !important;
    height: 40px;
}
  .thredsbar .threads-rows::before {
    left: 2px;
    width: 19px;
    height: 12px;
}
  [darkmode] .navbar-collapse ul.sidebar-menu li a {
    background: #383838;
  }
  .somewhere-pad-0.row {
    padding-top: 20px;
  }
  .header-nav .navbar-nav {
    flex-direction: row;
    margin-right: 15px !important;
  }
  .header-nav .mobilemenu ul.web-none li:last-child {
    display: none;
}
  .header-nav .mobilemenu {
    margin-left: 20px;
  }
  .header-nav {
    padding: 0 15px;
  }
  .header-nav .navbar-nav a.homeicon {
    display: none;
  }
  /* .user-dropdown,
  .header-nav .navbar-nav a.nav-link {
    margin-left: 15px;
  } */
  .header-nav .mobilemenu {
    margin-left: 0px;
  }
  [darkmode] .header-nav .mobilemenu {
    filter: invert(1);
  }
  #theme-switch .switch-track {
    height: 40px;
    width: 90px;
    border-radius: 30px;
    transition: all 0.2s ease 0s;
    background: linear-gradient(180deg, #21a0da -28.9%, #9dd382 100%), #ffffff;
  }

  .user-detail-bar .detailleft .avatar-img {
    position: relative;
    margin-right: 18px;
    flex: 0 0 60px;
  }

  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  .Titlebar-btns {
  }

  .Titlebar-btns .sendbtn a {
    display: inline-block;
    margin-top: 10px;
  }

  .usertable table tbody tr td .number-bar .user-dropdown ul.Dropdown-listing {
    top: inherit;
    background: var(--base-purewhite);
    bottom: -20px;
    padding: 5px 15px;
    right: 20px;
  }

  .user-dropdown .Dropdown-listing li,
  .user-dropdown .Dropdown-listing li a {
    margin-bottom: 5px;
  }

  .user-dropdown .Dropdown-listing li a {
    margin-bottom: 0 !important;
  }

  .share-popup form button {
    top: 50%;
    transform: translate(0px, -50%);
  }

  .share-popup form button {
    top: 50%;
    transform: translate(0px, -50%);
  }

  .Titlebar-btns .searchbar {
    width: 100%;
  }

  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  
  .tabswithbutotn .buttonoverall-size {
    position: absolute;
    top: 0px;
    left: 0;
  }
  .digital-feeds .Description-bar p div {
    white-space: unset !important;
  }

  .digital-feeds .Description-bar p div span {
    margin-right: 3px;
  }

  .dashboard-title-bar select.form-select {
    font-size: 1.125rem;
    margin-top: 20px;
  }

  .profiletabsbar {
    margin: 20px 0;
  }

  .user-detail-bar .detailleft {
    display: block;
    /* width: 100%; */
  }

  .user-detail-bar .detailleft .awards-bar {
    margin: 0px 0 0px 0px;
  }

  .action-bar ul li,
  .action-bar ul li .accordion-button {
    margin-bottom: 10px;
  }

  .btn-primary {
    padding: 10px 20px;
  }

  .profile-user-detail
    .user-detail-bar
    .detailleft
    .user-detail
    h4.text-secondry {
    font-size: 22px;
  }

  .profile-user-detail
    .user-detail-bar
    .detailleft
    .user-detail
    .user-availbility
    h6.text-lightgray {
    color: var(--darkmode-offwhite);
  }

  .profile-user-detail .user-edit-cover ul li a {
    font-size: 1rem;
    padding: 12px 20px;
  }

  .digital-tabs li .nav-link {
    padding: 10px 20px;
  }

  .notfound-content h2 {
    font-size: 90px;
  }

  .notfound-content h4 {
    font-size: 1.25rem;
    margin-bottom: 20px;
  }

  .thredsbar {
    position: relative;
    padding: 20px 15px;
  }

  .notfound-content p {
    font-size: 1rem;
  }

  .profile-user-detail .user-detail-bar .detailleft .avatar-img {
    width: 200px;
    height: 200px;
    border-width: 4px;
  }

  .action-bar ul li .accordion-button {
    margin: 0 !important;
  }

  .Notfy-block {
    padding: 20px 10px;
  }

  .notification-title ul li {
    margin-left: 10px;
  }

  .notification-title ul li .btn-nill {
    font-size: 0.875rem;
  }

  

  .user-detail-bar .detailleft .avatar-img {
    position: relative;
    margin-right: 15px;
  }

  .profile-user-detail .user-detail-bar .detailleft {
    margin-bottom: 10px;
  }
  .profile-user-detail .user-edit-cover ul li {
    margin-bottom: 10px;
}
  .profile-user-detail {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  body {
    padding-bottom: 100px;
  }

  .Whatsmind-bar {
    margin-bottom: 40px;
  }

  .sidebar-profile {
    display: none;
  }

  .header-nav {
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100%;
  }

  .Mob-Footerbar {
    display: block;
    z-index: 9999;
  }

  /* .mob-none {
    display: none !important;
  } */

  .mainheader {
    padding: 15px 0;
  }

  .mobilemenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .outline-primary,
  .btn-outline-primary {
    padding: 10px 20px;
  }
  .addpack.m-0 {
    margin: 0;
  }
  .addpack.align-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .mobilemenu ul.web-none {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .container {
    max-width: 100%;
    padding: 0 15px;
  }

  .mobilemenu ul.web-none li {
    margin-right: 15px;
  }
li.svggray svg path {
    fill: var(--base-text-lightgray);
}
  .mobilemenu ul.web-none li a {
    font-size: 1.25rem;
    color: var(--base-default-text);
  }

  .mobilemenu button.navbar-toggler {
    background: transparent;
    border: none !important;
    padding: 0;
    outline: none !important;
    box-shadow: none !important;
  }

  .navbar-collapse ul.sidebar-menu li a {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 10px;
    background: var(--base-lightgray);
    border-bottom: 1px solid #f1f1f1;
    color: var(--base-text-lightgray);
  }

  .navbar-collapse ul.sidebar-menu li.active a {
    background-color: var(--base-primary);
    color: var(--base-purewhite);
  }

  .navbar-collapse ul.sidebar-menu li a svg {
    margin-right: 10px;
  }
  .header-nav .navbar-nav a.nav-link {
    padding: 0px 0;
  }
  .body-wrapper {
    padding: 80px 0 80px 0 !important;
  }

  .user-detail-bar .user-edit-cover ul {
    justify-content: flex-end;
  }
  profile-user-detail .user-edit-cover ul li {
    margin-bottom: 15px;
}
  .userleftside {
    align-items: flex-start;
  }
  .user-detail-bar .detailleft .avatar-img {
    position: relative;
    margin-bottom: 10px;
  }

  ul.profiletabsbar li a {
    padding: 12px 30px;
    display: inline-block;
    margin-bottom: 15px;
  }

  .digital-tabs li:first-child .nav-link,
  ul.profiletabsbar li:first-child a {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .digital-tabs li:last-child .nav-link,
  ul.profiletabsbar li:last-child a {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .header-nav {
    position: fixed;
    top: 0;
    z-index: 5;
    width: 100%;
  }

  .profile-user-detail .user-edit-cover ul li {
    margin-left: 0;
    margin-right: 10px;
  }

  .text-end .save-form-btn {
    text-align: left !important;
    margin-top: 20px;
  }
  .text-end {
    text-align: right!important;
    display: flex;
    column-gap: 20px;
    justify-content: end;
}
.ql-snow .ql-picker{
  height: auto;
}

  .editor-input .form-select {
    padding: 11px 20px;
  }

  .account-verify .verify-input {
    display: block;
  }

  .account-verify .verify-input input.form-control {
    max-width: 100%;
  }

  .digital-feeds {
    padding: 10px;
    margin-bottom: 20px;
  }

  .profile-user-detail {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  .profile-feature-image {
    height: 150px;
    overflow: hidden;
    margin-top: -20px;
  }

  .action-bar ul li,
  .action-bar ul li .accordion-button {
    
    margin-left: 10px;
  }
  .action-bar ul li .list-text, .action-bar ul li .list-text span.number, .action-bar ul li, .action-bar ul li p {
    font-size: 0.875rem;
}

  .hoverdropdown button,
  .action-bar ul li .accordion-button {
    font-size: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .action-bar ul li span.number {
    font-size: 0.875rem;
  }

  .thredsbar .threads-rows {
    padding-left: 30px;
    position: relative;
  }

  .verification-bar .verification-media-upload {
    display: block;
  }

  .verification-bar {
    padding: 15px;
  }

  .verification-bar .verification-media-upload .verify-content {
    margin-top: 20px;
  }

  .thredsbar .threads-rows::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    left: 10px;
    top: 0;
    background: var(--base-text-lightgray);
  }

  .Auth-bar .Authbar-innerbox {
    padding: 20px 20px;
  }
  .dashboard-title-bar {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .dashboard-title-bar .sendbtn {
    flex: 0 0 20%;
    max-width: 20%;
    margin: 20px 0px 0px 0;
  }

  .dashboard-title-bar .sendbtn a {
    margin-top: 0px;
    margin-right: 10px !important;
    display: block;
  }

  .dashboard-title-bar .searchbar {
    margin-top: 20px;
  }
  .Titlebar-btns {
    flex-wrap: wrap;
  }
  .sidebar-menu.col-lg-3{
    margin-top: 0 !important;
  }
  .header-nav .navbar-nav{
    column-gap: 15px;
  }
  .terms-content {
    max-height: 500px;
    overflow: auto;
}
}
.mobilemenu ul.web-none .user-searchform button svg.crossicon {
  display: none;
}
@media (max-width: 767px) {
  /* .ql-toolbar.ql-snow {
    margin-top: -83px !important;
} */
.reply-post .whtsmind-btn-space {
  position: relative;
  bottom: inherit;
  right: inherit;
  padding: 12px;
}
.reply-post p.counter-text {
  right: inherit;
  left: 15px;
}
.thredsbar .accordionitem {
  max-height: 250px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 100px;
  margin-bottom: 160px;
}
.digital-feeds .Description-bar p {
  word-break: inherit !important;
}
.Actions-modal .modal-header button.btn-close {
  opacity: 1;
  font-size: 1rem;
  filter: inrevert !important;
}
.user-detail-bar .detailleft .rules-tag {
  margin: 0;
  line-height: 10px;
}
[darkmode] .Actions-modal .modal-header button.btn-close {
  filter: inrevert !important;
}
.header-nav .navbar-nav .notify-add .notification-dropdown .userleftside {
  display: flex;
}
.quill.quillactive .ql-toolbar.ql-snow {
  margin-top: 0 !important;
}
  [darkmode] .reply-post.reply-fix-bottom {
    background: #272728;
}
ul.actionrytbar li p {
  margin-right: 5px;
  text-align: start;
}
.edit-post-buttons .cancel {
  padding: 8px 12px;
}
#theme-switch {
  margin-right: 10px !important;
}
.Whatsmind-bar p.counter-text {
  padding: 20px 10px;
}
.header-nav .navbar-nav .notify-add svg {
  font-size: 25px;
}
.mobilemenu ul.web-none .search-input-form input {
    position: absolute;
    top: 0;
    left: -170px;
    width: 200px;
    transform: translate(0px, 44px);
    display: none;
}
.mobilemenu ul.web-none .search-input-form .user-searchform button svg.crossicon {
  display: none;
}

.mobilemenu ul.web-none .search-input-form .showSearch svg.searchicon {
  display: none;
}
.mobilemenu ul.web-none .search-input-form .user-searchform button svg {
  font-size: 30px;
}
.mobilemenu ul.web-none .search-input-form .showSearch svg.crossicon {
  display: block !important;
}

.search-input-form .user-searchform {
  display: flex;
}
.mobilemenu ul.web-none .search-input-form .user-searchform button {
    position: relative;
    top: inherit !important;
    right: inherit !important;
    transform: inherit !important;
}

.mobilemenu ul.web-none .search-input-form .user-searchform button svg {
    font-size: 30px;
}

.mobilemenu ul.web-none .search-input-form ul {
    left: -170px;
    width: 200px;
    top: 93px;
    overflow-x: hidden;
}

.mobilemenu ul.web-none .search-input-form .showSearch input {
    display: block;
}
  .action-bar ul.actionleftbar .list-text {
    font-size: 0;
    line-height: 0;
}
.actionrytbar li.viewpost-btn {
  display: flex;
}
.action-bar ul.actionleftbar .list-text span.number {
    font-size: 20px !important;
    line-height: 1 !important;
    margin: 0 !important;
}
.action-bar ul.actionleftbar li {
    margin: 0;
    display: flex;
    align-items: center;
}
.action-bar ul.actionleftbar {
    column-gap: 15px;
}
.action-bar ul.actionleftbar li .accordionhead {
    font-size: 0;
    line-height: 0;
    display: flex;
    align-items: center;
}
.action-bar ul.actionleftbar li .accordionhead span.number {
    font-size: 20px;
    line-height: 1 !important;
    margin: 0;
}
  ul.actionrytbar {
    flex: 0 0 30%;
}
  .userleftside {
    display: block;
}
  .digital-feeds .Description-bar p{
    font-size: 1.125rem !important;
  }
  .Auth-bar .Authbar-innerbox form .authinputbar .form-control {
    padding: 10px !important;
    font-size: 14px;
}
  .Actions-modal .modal-content {
    padding: 20px 20px;
}
  .medium .digital-feeds .Description-bar p {
    font-size: 19px !important;
}
.large .digital-feeds .Description-bar p {
  font-size: 1.375rem !important;
}
.small .digital-feeds .Description-bar p {
  font-size: 1rem !important;
}
  .user-detail-bar .detailleft .avatar-img {
    margin-right: 0px !important;
    max-width: inherit !important;
}
.thredsbar .thredsbar .accordionitem .replyinput textarea {
  padding: 10px 45px 10px 10px !important;
  height: 40px;
}
  .Whatsmind-bar {
    margin-bottom: 20px;
}
.thredsbar .accordionitem .user-detail-bar {
  margin-top: 20px;
}
  .reply-post form .replyinput .form-control {
    border-radius: 8px;
    padding: 10px 60px 10px 10px !important;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1rem;
    height: 40px;
}
.thredsbar {
  position: relative;
  padding: 15px 5px;
}
.thredsbar .accordionitem .accordionitem {
  max-height: initial;
  overflow: hidden;
}
.reply-post form .btn {
  font-size: 20px;
}
.Foundationalmodal button.btn-close {
  top: 20px !important;
  right: 0 !important;
}
.thredsbar .threads-rows ul.actionleftbar {
  flex: 0 0 100% !important;
  justify-content: flex-start;
}
.treeicon img {
  width: 36px;
}
  /* .action-bar ul li .list-text, .action-bar ul li, .action-bar ul li p {
    font-size: 0;
} */
.action-bar ul li, .action-bar ul li .accordion-button {
  margin-left: 10px;
}
.action-bar ul li {
  margin-left: 0px;
}
.action-bar ul li .list-text span.number{
  font-size: 0.875rem !important;
}
  .reply-post {
    margin-top: 20px;
}

  .awards-modal .row .col-md-4 {
    flex: 0 0 33.33%;
}
.digital-feeds .digital-feeds.diffrentiate-bar .detailleft {
  width: 100%;
}
  .digital-feeds .avatar-img {
    flex: 0 0 60px !important;
    max-width: 60px;
  }

  .digital-feeds .avatar-img img {
    width: 60px;
    height: 60px;
  }
  .action-bar ul.actionleftbar {
    flex: 0 0 70%;
  }
  .action-bar ul.actionleftbar li p {
    font-size: 0;
  }
  .digital-feeds .user-active-timer ul li {
    font-size: .75rem;
  }
  .notification-content .notification-text {
    max-width: 90%;
    flex-wrap: wrap;
  }
  /* .Notfy-block .user-availbility {
    display: block !important;
  } */
  .digital-feeds
    .digital-feeds.diffrentiate-bar
    .user-availbility
    h6.text-lightgray {
    word-break: break-word;
  }
  .digital-feeds .digital-feeds.diffrentiate-bar {
    margin-left: 0;
  }

  ul.trending-hashtag-list {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 15px;
  }
  ul.trending-hashtag-list li {
    font-size: 1.125rem !important;
    margin-right: 10px;
  }
  .pagetitle {
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.75rem;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    margin-bottom: 20px;
    padding-bottom: 5px;
    display: inline-block;
    padding-right: 10px;
  }
  .welcomepopup .modal-header {
    padding: 0 40px 20px 0;
}
.modal.show .modal-dialog {
  max-width: 96%;
}
  .terms-content h4 {
    color: #212121;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .terms-content h5 {
    font-size: 1rem;
    font-weight: 500;
    color: #212121;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .terms-content p {
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 10px;
  }
  .awards-modal .modal-header button.btn-add {
    padding: 10px 10px;
    font-size: 0.875rem;
    line-height: 1rem;
  }
  .notification-text p {
    font-size: 0.875rem !important;
  }
  .notification-title h4 {
    font-size: 1.125rem;
  }
  .dashboard-title-bar .sendbtn {
    flex: 0 0 50%;
    max-width: 50%;
    margin: 10px 0px 0px 0;
  }
  .flexbar {
    display: block;
  }
  .flexbar ul.admintable-tabs {
    margin-top: 20px;
  }
  .Titlebar-btns {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .restrict-logins li {
    margin: 0 5px 0 0 !important;
    flex: 0 0 50px;
    position: relative;
    top: 8px;
  }

  .restrict-logins li p {
    font-size: .75rem;
  }
  .user-preview {
    margin-top: 12px;
}
.ql-emoji {
  display: none !important;
}

  .restrict-logins {
    justify-content: flex-end;
  }

  .restrict-logins li .form-switch input {
    position: relative;
    margin: 0 0 0 0;
  }
  .dashboard-boxes .dash-boxes-in {
    padding: 10px 15px;
    background: var(--base-purewhite);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12),
      0px 12px 20px 8px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
    margin-bottom: 20px;
    display: block;
  }
  .Notfy-block .notification-content .detailleft .avatar-img {
    flex: 0 0 60px;
    margin-right: 10px;
  }
  .Notfy-block .notification-content .detailleft {
    width: auto;
  }
  .Notfy-block .notification-content .detailleft {
    width: auto;
  }
  .Notfy-block .notification-content .detailleft .avatar-img {
    flex: 0 0 60px;
    margin-right: 10px;
  }
  .header-nav .navbar-nav .notify-add .notification-dropdown {
    padding: 0px;
  }
  .header-nav
    .navbar-nav
    .notify-add
    .notification-dropdown
    .detailleft
    .userleftside
    .user-detail
    .user-availbility
    h6.text-lightgray {
    word-break: break-word;
    white-space: normal;
  }
  .header-nav
    .navbar-nav
    .notify-add
    .notification-dropdown
    .notification-head {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .header-nav .navbar-nav .notify-add .notification-dropdown .detailleft {
    flex: 0 0 80%;
  }
  .header-nav
    .navbar-nav
    .notify-add
    .notification-dropdown
    .detailleft
    .userleftside {
    width: 100%;
  }
  .header-nav
    .navbar-nav
    .notify-add
    .notification-dropdown
    .detailleft
    .userleftside
    .user-detail
    h4 {
    font-size: 0.875rem;
    margin-bottom: 2px;
  }

  .header-nav
    .navbar-nav
    .notify-add
    .notification-dropdown
    .user-detail-bar
    .notification-text {
    max-width: 85%;
  }
  .action-bar {
    align-items: flex-start;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.action-bar ul.actionleftbar {
  justify-content: flex-end;
}
.action-bar ul.actionrytbar {
  justify-content: flex-end;
  flex-direction: row-reverse;
}
.text_level{
  margin-top: 0;
}
.profile-user-detail .user-edit-cover ul li {
  margin-bottom: 8px;
}
.action-bar ul li.awardsbtn-disable{
  display: none;
}

/* , .Mob-Footerbar .mob-foot-menu li a svg */
.Mob-Footerbar .mob-foot-menu li.user-dropdown img {
  font-size: 60px !important;
  width: 60px !important;
  height: 60px !important;
  margin-bottom: 0px;
}
.Mob-Footerbar{
  padding: 5px 15px;
}
.header-nav .container {
  padding-left: 0;
  padding-right: 0;
}
.mainheader {
  padding: 5px 0;
}
.cursor-pointer.helpicon{display: none;}
.welcomepopup .modal-body {
  padding: 0 !important;
  overflow: hidden;
}
.Uploaded-user .Imagebar {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  border: 10px solid var(--base-purewhite);
  position: relative;
}
.Uploaded-user p{
  left: 143px;
}
.info_circle {
  width: 38px;
  height: 38px;
}
}

@media (max-width: 574px) {
  .header-nav .navbar-nav .notify-add svg {
    font-size: 25px;
}
.refer_header.modal-header {
  padding-right: 30px;
  display: flex;
  text-align: left;
}
#emoji-palette #tab-panel span.bem {
  margin: 2px !important;
}
#theme-switch {
  margin-right: 0px !important;
}
.header-nav .navbar-nav .notify-add .notification-dropdown .userleftside .rules-tag {
  line-height: 1;
  margin: 0;
}
.Notfy-block .user-detail-bar .avatar-img {
  margin-right: 10px !important;
}
.header-nav .navbar-nav .notify-add .notification-dropdown .user-active-timer svg {
  font-size: 20px;
}
.header-nav .navbar-nav .notify-add .notification-dropdown .notification-text {
  line-height: 1;
  margin-top: 10px;
}
.hoverdropdown .dropdown-menu .dropdown-item p svg {
  font-size: 16px;
}
.refer_header.modal-header ~ .modal-body p {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
}
.refer_header.modal-header .modal-title.h4 {
  font-size: 16px;
  line-height: 18px;
}
.refer_header.modal-header .totalshare {
  font-size: 14px;
  line-height: 16px;
}
.hoverdropdown .dropdown-menu .dropdown-item p svg {
  font-size: 16px ;
}
.hoverdropdown .dropdown-menu {
  min-width: 200px;
}
.action-bar ul.actionleftbar li.Agreeli {
    order: 3;
}
.action-bar ul.actionleftbar li.Disagreeli {order: 6;margin: 0;}
.action-bar ul.actionleftbar li.Amplifyli {
    order: 2;
}
.action-bar ul.actionleftbar li.Reportsli {
    order: 1;
}
.action-bar ul.actionleftbar li.Threadsli {
    order: 5;
    margin: 0;
}
.action-bar ul.actionleftbar li.editpostli {
  order: 4;
  margin: 0;
  min-width: 43px;
}
.action-bar ul.actionleftbar li.viewpostli {
    display: none;
}
.action-bar ul.actionleftbar li {
    margin-bottom: 10px;
}
  .action-bar ul.actionleftbar {
    flex: 0 0 60%;
}
ul.actionrytbar {
  flex: 0 0 40%;
}
  .user-detail-bar .user-edit-cover ul {
    justify-content: flex-start;
}

  .digital-feeds .digital-feeds {
    padding-top: 40px;
}
.empty-bar h4 {
  font-size: 1.125rem;
}
.empty-bar img {
  margin: 0 auto 20px auto;
  height: 100px;
}
.empty-bar {
  padding: 20px 0;
}
.digital-feeds .digital-feeds .user-active-timer {
    position: absolute;
    top: -30px;
    right: 10px;
}
  .Mob-Footerbar .mob-foot-menu li a, .Mob-Footerbar .mob-foot-menu li.user-dropdown {
    font-size: 0.875rem;
    line-height: 1rem;
}
.followbtn {
  min-width: auto;
  padding: 4px 7px;
  font-weight: 500;
}
.Mob-Footerbar .mob-foot-menu li.user-dropdown .Dropdown-listing ul li a svg {
  font-size: 1.125rem !important;
  width: auto !important;
  height: auto !important;
}
  .awards-modal .row .col-md-4 {
    flex: 0 0 50%;
}
.action-bar ul li, .action-bar ul li .accordion-button {
  margin-left: 3px;
}

.action-bar ul li svg, .action-bar ul li .accordion-button svg {
  font-size: 30px;
}
.Whatsmind-bar {
  margin-bottom: 20px;
}
  .imagebarupload p.succesful {
    margin: 0px 0 0 10px;
    font-size: .75rem;
    line-height: 1.125rem;
  }
  ul.trending-hashtag-list {
    padding: 10px;
    margin-bottom: 10px;
  }
  .btn-primary {
    padding: 8px 14px;
    font-size: 1rem;
  }

  .user-detail-bar .detailleft .awards-bar li img {
    width: 24px !important;
    height: 24px !important;
  }
  .viewmorebtn {
    font-size: 0.875rem;
    line-height: 1;
  }
  .user-detail-bar .detailleft .awards-bar {
    margin: 0;
  }
  .user-detail-bar .detailleft .follow-bar {
    justify-content: space-between;
  }
  .edit-post-buttons {
    align-items: center;
    -webkit-column-gap: 20px;
    column-gap: 20px;
    display: flex;
    justify-content: flex-end;
}
  .Auth-bar .Authbar-innerbox form .authcheckbox .btn-simple {
    text-align: right;
  }
  .body-wrapper {
    padding: 70px 0 90px 0 !important;
  }
  
  .user-detail-bar .detailleft .awards-bar li img {
    width: 24px !important;
    height: 24px !important;
  }

  .user-detail-bar .detailleft .follow-bar {
    justify-content: space-between;
  }
  .buymore-modal .Awrds-li h3 span {
    font-size: 0.875rem;
  }
  .notification-content .userleftside {
    display: flex;
  }
  .notification-content .userleftside .avatar-img {
    flex: 0 0 40px;
    margin-bottom: 0;
  }
  .Dropdown-listing
    .arrowbar
    .notification-content
    .user-detail-bar
    .user-detail
    .rules-tag
    .levelbar {
    top: 0 !important;
  }
  .Dropdown-listing
    .arrowbar
    .notification-content
    .user-detail-bar
    .user-detail
    .user-availbility {
    margin: 0;
  }
  .notification-dropdown .notification-content .user-detail-bar {
    display: flex;
  }
  .notification-dropdown .notification-content .notification-text {
    display: block;
  }
  .notification-dropdown .notification-content .notification-text p {
    font-size: 0.875rem !important;
    display: inline !important;
  }
  .notification-dropdown {
    width: 270px !important;
    left: inherit !important;
    right: -50px !important;
  }
  .profile-user-detail .user-edit-cover ul li a {
    font-size: 0.875rem;
    padding: 10px 10px;
  }
  .profile-user-detail .user-edit-cover ul li a,
  .profile-user-detail .user-edit-cover ul li button {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    border-radius: 5px;
    padding: 10px 10px;
  }
  .header-nav .navbar-brand {
    flex: 0 0 initial;
  }
  .header-nav .mobilemenu {
    margin-left: 10px;
  }
  .header-nav .navbar-nav .user-dropdown img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  /* .user-dropdown,
  .header-nav .navbar-nav a.nav-link {
    margin-left: 10px;
  } */
  .profile-user-detail .user-detail-bar .detailleft {
    margin-bottom: 20px;
    width: 100%;
  }

  .action-bar .flagbtnbar li button {
    padding: 10px 20px;
    font-size: 0.875rem;
  }

  .profiletabsbar {
    margin: 20px 0;
    display: block;
  }

  .editor-input .form-select {
    padding: 11px 20px;
  }

  .profile-user-detail .user-detail-listing {
    justify-content: flex-start;
  }


  .mobilemenu ul.web-none li {
    margin-right: 5px;
  }
  .header-nav .navbar-nav {
    column-gap: 15px;
}
.user-edit-cover {
  min-width: 100%;
}
[darkmode] .user-edit-cover li a.green-button {
  background: transparent;
}
  .mobilemenu ul.web-none li svg{
    font-size: 24px;
    color: #AAAAAA;
  }
  .navbar-nav svg {
    font-size: 24px;
    color: #AAAAAA;
}
[darkmode] .mobilemenu ul.web-none li svg{
  color: var(--base-default-text);
}
  .header-nav .navbar-nav {
    margin-right: 0px !important;
}
  .tabswithbutotn .buttonoverall-size{
    right: inherit;
    top: -50px;
  }
  .digital-tabs li .nav-link {
  padding: 9px 15px;
  }
  .tabswithbutotn {
    position: relative;
    margin-top: 60px;
  }
  .actionrytbar {
    flex: 0 0 20%;
    text-align: end;
    justify-content: flex-end !important;
}

#theme-switch .switch-thumb {
  height: 26px;
  left: 50%;
  top: 50%;
  width: 26px;
  transform: translate(-50%,-50%);
  overflow: hidden;
}
#theme-switch .switch-track{
  height: 32px;
  width: 32px; 
}
body #theme-switch .switch-check, body #theme-switch .switch-x {
  position: absolute;
  top: inherit !important;
  left: inherit !important;
  right: inherit !important;
  transform: inherit !important;
  bottom: inherit !important;
}
[darkmode] #theme-switch .switch-thumb {
  left: 50%;
}
[darkmode] #theme-switch .switch-icon svg {
  font-size: 1rem;
}
#theme-switch .switch-icon svg {
  font-size: 20px;
  line-height: 1;
}
.reply-post .ql-toolbar.ql-snow{
  margin-top: 0;
}
.Reportsli svg,.viewpostli svg {
  height: 25px;
}
.Uploaded-user .Imagebar {
  width: 180px;
  height: 180px;  
}
.Uploaded-user p{
  left: 130px;
  font-size: 14px;
}
.Uploaded-user .Imagebar .userup-in {
  right: -4px;
  width: 45px;
  height: 45px;
}

}
@media (max-width: 365px){
  .Mob-Footerbar .mob-foot-menu li a, .Mob-Footerbar .mob-foot-menu li.user-dropdown {
    font-size: .75rem;
    line-height: .875rem;
}
.ql-snow.ql-toolbar button, .ql-snow .ql-toolbar button {
  width: 18px !important;
}
}
